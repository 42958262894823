import cn from 'classnames';
import { Chevron } from '@components/icons';
import { Title } from '@components/typography';
import { SubMenuGroupProps } from '@ts/contentful';
import variables from '@styles/export.module.scss';
import ImagesMenu from '../ImagesMenu';
import AllTopsMenu from '../AllTopsMenu';
import styles from './SubMenuGroup.module.scss';

const SubMenuGroup = ({ isActive, menuGroup, toggleDesktopSubNav, toggleMobileSubNav, debounceHover }: SubMenuGroupProps) => {
	const subNavStyle = cn(styles['sub-menu-group'], {
		[styles['is-visible']]: isActive,
	});

	const useColumns = !!menuGroup.subMenuGroup ? menuGroup.subMenuGroup[0].hasOwnProperty('subMenuGroup') : false

	const content = useColumns ? <AllTopsMenu menuGroup={menuGroup} /> : <ImagesMenu menuGroup={menuGroup} />;

	return (
		<div
			key={`sub-menu-group-${menuGroup.handle}`}
			className={subNavStyle}
			aria-labelledby={`subnavbutton-${menuGroup.text}`}
			aria-hidden={isActive}
			onMouseLeave={() => {
				debounceHover(() => {
					toggleDesktopSubNav(false, menuGroup);
				});
			}}
			onMouseEnter={() => {
				debounceHover(() => {
					toggleDesktopSubNav(true, menuGroup);
				});
			}}
		>
			<div className={styles['button-container']}>
				<button
					type='button'
					className={styles['mobile-back-btn']}
					onClick={() => {
						toggleMobileSubNav(false, menuGroup);
					}}
				>
					<Chevron direction='left' width={20} height={20} extraStyles={{ display: 'inline', color: variables.blue }} />
					<Title>{menuGroup.text}</Title>
				</button>
				{content}
			</div>
		</div>
	);
};

export default SubMenuGroup;
