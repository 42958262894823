import { NormalizedCartLine, NormalizedCart, Bundle, Prescription } from '@ts/cart';
import { BaseCartLine, Cart } from '@ts/shopify-storefront-api';
import { NormalizedProduct, ProductInNormalizedVariant } from '@ts/product';
import { SKU_BLUE_LIGHT, SKU_SUN_LENS } from '@utils/constants/cart';
import { RX_TYPE, PRODUCT_TYPES } from '..';
import { normalizeMetafields, normalizeProductVariant } from './normalize-product';

const TEMP_KEY = 'tempKey';

/** PairCare product should not be grouped with other accessories  */
const belongsInAccessoryGroup = (line: NormalizedCartLine) => {
	return line.variant.handle !== 'pair-care';
};

const normalizeCartLines = (lines: BaseCartLine[]): NormalizedCartLine[] => {
	return lines.reduce((result, current) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { attributes, merchandise, ...rest } = current;
		const newItem = {
			...rest,
			variant: normalizeProductVariant(current.merchandise, {
				id: current.merchandise.product.id,
				handle: current.merchandise.product.handle,
				title: current.merchandise.product.title,
				normalizedMetafields: normalizeMetafields(current.merchandise.product),
				productType: current.merchandise.product.productType as NormalizedProduct['type'],
				tags: [],
			}),
			properties: Object.assign({}, ...current.attributes.map(({ key, value }) => ({ [key]: value }))),
		};
		const isDiscountedItem = current.merchandise.sku.includes(SKU_SUN_LENS) || current.merchandise.sku.includes(SKU_BLUE_LIGHT);
		const price = isDiscountedItem ? current.cost.totalAmount.amount : current.cost.subtotalAmount.amount;

		newItem.variant.price = {
			amount: parseFloat(price),
			currencyCode: current.cost.subtotalAmount.currencyCode,
		};
		result.push(newItem);

		return result;
	}, [] as NormalizedCartLine[]);
};

const parseItemType = (p: ProductInNormalizedVariant) => {
	if (p.type.includes(PRODUCT_TYPES.BASE_FRAME)) return 'frame';
	if (p.type.includes(PRODUCT_TYPES.TOP_FRAME)) return 'tops';
	if (p.handle.includes('pair-care')) return 'insurance';
	if (p.type.includes(PRODUCT_TYPES.LENS)) return 'lenses';
	return 'other';
};

/** Adds Line Item data to the appropriate property within its Bundle record
 * @example
 * let item;
 * let key = 'abcd_1234'
 * item.variant.product.type = 'BASE_FRAME_SR' or 'BASE_FRAME'
 * existingBundles[key].base.frame = item
 */
const associateItemWithBundle = (existingBundles: Record<string, Bundle>, item: NormalizedCartLine, key: string) => {
	const type = parseItemType(item.variant.product);
	const itemIsPartOfBase = type === 'frame' || type === 'lenses' || type === 'insurance';

	if (itemIsPartOfBase) {
		if (!existingBundles[key].base) {
			existingBundles[key].base = {
				lenses: [],
				frame: {} as NormalizedCartLine,
				prescription: {
					type: item.properties._prescriptionType,
					submissionMethod: item.properties._submissionMethod,
				} as Prescription,
			};
		}

		if (type === 'lenses') {
			existingBundles[key].base.lenses?.push(item) ?? (existingBundles[key].base.lenses = [item]);
		} else if (type === 'frame') {
			existingBundles[key].base[type] = item;
			existingBundles[key].base['prescription'] = {
				type: item.properties._prescriptionType as RX_TYPE,
				submissionMethod: item.properties._submissionMethod,
			};
		} else {
			existingBundles[key].base[type] = item;
		}
	} else {
		existingBundles[key][type]?.push(item) ?? (existingBundles[key][type] = [item]);
	}
};

/** Attempts to place the given Top or Accessory Line Item within an existing Bundle.
 *
 * Top Frames can be added to a Bundle with the same frame size.
 *
 * Accessories can be added to any Bundle */
const findBundleKeyForItem = (existingBundles: Record<string, Bundle>, item: NormalizedCartLine, key: string) => {
	return Object.entries(existingBundles).map(([k, v]) => {
		try {
			if (v.base.frame.variant.product.name.includes(item.variant.option)) {
				return k;
			}
			if (parseItemType(item.variant.product) === 'other') {
				return k;
			}
		} catch (error) {
			// couldn't find a bundle
			return key;
		}
	})[0];
};

export const generateCartBundles = (lines: NormalizedCartLine[], optimistic = false): Record<string, Bundle> => {
	if (!lines.length) return {} as Record<string, Bundle>;

	// Create an empty Record of Bundles
	const generatedBundles = {} as Record<string, Bundle>;

	// Create a temporary key that will be used by any item that does not already belong to a Bundle, or cannot be added to an existing one
	// Use a constant for this so that client and server values are identical, fixes jitter during loading state
	const tempBundleKey = TEMP_KEY;

	// Separate the existing bundles from lines that don't have a bundle yet
	const linesWithKey = lines.filter(line => !!line?.properties?._bundle_key);
	const linesWithNoKey = lines.filter(line => !line?.properties?._bundle_key);

	for (let i = 0; i < linesWithKey.length; i++) {
		const line = linesWithKey[i];
		const currentItemKey = line.properties._bundle_key;

		// If this item is the first of its Bundle to appear, add a record of this Bundle
		if (!generatedBundles[currentItemKey]) generatedBundles[currentItemKey] = { key: currentItemKey, optimistic };

		// Add this item data to the appropriate property within its Bundle record
		associateItemWithBundle(generatedBundles, line, currentItemKey);
	}

	for (let i = 0; i < linesWithNoKey.length; i++) {
		const line = linesWithNoKey[i];

		// If we _always_ want this line in a separate "others" section, we just use the temporary key.
		// Otherwise, check the Bundle Record for an existing Bundle that _could_ contain this item.
		// If none exist, group the item using the temporary key.
		const currentItemKey = belongsInAccessoryGroup(line)
			? tempBundleKey
			: findBundleKeyForItem(generatedBundles, line, tempBundleKey) ?? tempBundleKey;

		// If this item is the first of its Bundle to appear, add a record of this Bundle
		if (!generatedBundles[currentItemKey]) generatedBundles[currentItemKey] = { key: currentItemKey, optimistic };

		// Add this item data to the appropriate property within its Bundle record
		associateItemWithBundle(generatedBundles, line, currentItemKey);
	}

	// Sort tops alphabetically
	for (const key in generatedBundles) {
		if (Object.prototype.hasOwnProperty.call(generatedBundles, key)) {
			const bundle = generatedBundles[key];
			bundle?.tops?.sort((a, b) => a.variant.product.name.localeCompare(b.variant.product.name));
			bundle?.base?.lenses?.sort((a, b) => a.variant.product.name.localeCompare(b.variant.product.name));
			bundle?.other?.sort((a, b) => a.variant.product.name.localeCompare(b.variant.product.name));
		}
	}
	return generatedBundles;
};

export function normalizeCart(cart: Cart): NormalizedCart {
	const normalizedCartLines = normalizeCartLines(cart.lines.edges.map(({ node }) => ({ ...node })));
	const calculatedSubtotal = normalizedCartLines.reduce((acc, line) => acc + line.variant.price.amount, 0);

	return {
		id: cart.id,
		totalQuantity: cart.totalQuantity,
		subtotal: calculatedSubtotal,
		lines: normalizedCartLines,
		bundles: generateCartBundles(normalizedCartLines),
		checkoutUrl: cart.checkoutUrl,
		buyerIdentity: cart.buyerIdentity,
	};
}
