import { Asset, Entry } from 'contentful';
import { ContentfulImage, ContentfulVideo } from '@ts/contentful';
import { getProduct } from '@services/shopify';
import { getShopifyIdFromBase64 } from '..';

// !!Need Help? Further Documentation: https://www.notion.so/paireyewear/Code-Documentation-Contentful-17a85b978e9b4455b82db454782043ed?pvs=4


/**
 * Normalizes Contentful data to a consistent format across content types.
 *
 * @param {Contentful Entry}
 * @returns object
 */
export const normalizeContentfulEntry = async <T>(entry: Entry<T>) => {
	if (!entry.fields) {
		console.error(
			'Contentful Content Entry is Missing - Check the Contentful field with "Multiple References" for Missing Entries'
		);
		return null;
	}
	const normalized = {}

	for (const [key, value] of Object.entries(entry.fields)) {
		if (value?.sys && value.sys.type === 'Asset') {
			normalized[key] = normalizeAsset(value);
			continue;
		}
		if (key === 'featureProduct') {
			normalized['product'] = await normalizeProductField(value);
			continue;
		}

		// TODO: check OR part
		if ((value?.sys && value.sys.type === 'Entry')) {
			normalized[key] = await normalizeContentfulEntry(value);
			continue;
		}

		if (Array.isArray(value) && key !== 'utmTerm' && value[0]?.sys?.type === 'Entry' && key !== 'baseFrameProducts') {
			normalized[key] = await Promise.all(value.map(entry => normalizeContentfulEntry(entry)));
			continue;
		}

		normalized[key] = value;
	}
	return {
		locale: entry.sys.locale,
		metadata: entry.metadata,
		contentType: entry.sys.contentType.sys.id, // 03-20-2023 - Will delete after removal from other files
		componentType: entry.sys.contentType.sys.id,
		...entry.fields,
		...normalized,
	};
};

//--- ASSET NORMALIZATION FUNCTIONS ---//
export function normalizeImage({ sys, metadata, fields: { title, description = null, file } }: Asset) {
	return {
		metadata,
		title,
		description,
		fileName: file.fileName,
		width: file.details.image?.width ?? 100,
		height: file.details.image?.height ?? 100,
		url: `https:${file.url}`,
		contentType: file.contentType,
		locale: sys.locale,
	} as ContentfulImage;
}

export function normalizeVideo({ description, file, title }): ContentfulVideo {
	return {
		title,
		description,
		fileName: file.fileName,
		contentType: file.contentType,
		url: `https:${file.url}`,
	};
}

export function normalizeAsset(asset): ContentfulVideo | ContentfulImage {
	if (asset.fields?.file.contentType === 'video/mp4') return normalizeVideo(asset.fields);
	return normalizeImage(asset);
}

async function normalizeProductField(productId: string) {
	const queryId = getShopifyIdFromBase64(productId);
	const product = await getProduct(`gid://shopify/Product/${queryId}`);
	return {
		...product,
	};
}

export const normalizeCustomBlock = ({ sys, metadata, fields: { componentName } }) => {
	if (!componentName) return null;

	return {
		metadata,
		componentType: `component${componentName}`,
		locale: sys.locale,
	}
};
