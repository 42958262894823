import { useState } from 'react';
import { m } from 'framer-motion';
import { Button, Flex, PdToolModal } from '@components/common';
import { Box, Close, EyeIcon } from '@components/icons';
import { Caption, Label } from '@components/typography';
import variables from '@styles/export.module.scss';
import { accordianHeightExpansion } from "@utils/motions";
import styles from './PhotoPd.module.scss';

const { green4 } = variables;

const PhotoPd = ({ isAccount = false, isPdNeeded = false, isMeasuredPd=false, submit }) => {
	const isHeapAvailable = typeof window !== 'undefined' && window.heap;
	const [hasMeasuredPd, setHasMeasuredPd] = useState(false);
	const [isPDMeasureContainer, setIsPDMeasureContainer] = useState(false);
	const [pDModalOpen, setPDModalOpen] = useState(false);
	const [pdToolInit, setPdToolInit] = useState(false);
	const [pdToolHasError, setPdToolHasError] = useState(false);
	const [hasScriptLoaded, setHasScriptLoaded] = useState(false);

	const handlePDMeasure = (pd: number) => {
		setHasMeasuredPd(true);
		setPDModalOpen(false);
		submit(pd);
		if(isHeapAvailable) {
			window.heap.track('PD Submission', {
				'Submission Location': 'Cart',
			})
		}
	};

	if (hasMeasuredPd || !isPdNeeded) {
		return (
			<Flex gap={3} className={styles['success']} fullWidth>
				<EyeIcon color={green4} />
				<Label>PD measured successfully!</Label>
			</Flex>
		);
	}

	if (isAccount && isPdNeeded && !isMeasuredPd) {
		return (
			<Flex style={{ padding: 0 }} justify='between' fullWidth column>
				<Caption small style={{ marginBottom: '1.6rem' }}>We’ve got your Rx—now we just need your pupillary distance (PD) to get started on your Pair. Use our handy tool to measure your PD now!</Caption>
				<Button
					disabled={!pdToolInit && !pdToolHasError}
					color='blue'
					onClick={() => setPDModalOpen(true)}
					data-cart-pd-measure
				>
					Measure My PD
				</Button>
				<PdToolModal
					open={pDModalOpen}
					setOpen={setPDModalOpen}
					setPdToolInit={setPdToolInit}
					setPdToolHasError={setPdToolHasError}
					hasScriptLoaded={hasScriptLoaded}
					onScriptLoaded={() => setHasScriptLoaded(true)}
					pdValueHandler={handlePDMeasure}
					strategy='onReady'
				/>
			</Flex>
		);
	}

	const animation = {
		...accordianHeightExpansion,
		animate: {
			...accordianHeightExpansion.animate,
			height: '100%',
		},
	};

	return isPDMeasureContainer ? (
		<m.div className={isAccount ? '' :styles['pd-container']} {...animation}>
			<Flex gap={3} justify='start' align='start' fullWidth>
				<Box width={20} height={20} />
				<Flex column fullWidth>
					<Label style={{ color: 'black' }}>Expedite your order processing!</Label>
					<Caption small color='black'>
						In order to process your order right away, check your prescription to see if you have PD and if not, use our tool to measure it now!
					</Caption>
					<Button
						disabled={!pdToolInit && !pdToolHasError}
						color='blue'
						onClick={() => setPDModalOpen(true)}
						data-cart-pd-measure
						style={{
							margin: '1.6rem 0 0.8rem',
						}}
					>
						Measure My PD
					</Button>
					<PdToolModal
						open={pDModalOpen}
						setOpen={setPDModalOpen}
						setPdToolInit={setPdToolInit}
						setPdToolHasError={setPdToolHasError}
						hasScriptLoaded={hasScriptLoaded}
						onScriptLoaded={() => setHasScriptLoaded(true)}
						pdValueHandler={handlePDMeasure}
						strategy='onReady'
					/>
				</Flex>
				{isAccount ? (
					<Button size='xsmall' linkStyle onClick={() => setIsPDMeasureContainer(false)}>
						Close
					</Button>
				) : (
					<Close wrapperClass={styles['close-icon']} label='close' onClick={() => setIsPDMeasureContainer(false)}/>
				)}
			</Flex>
		</m.div>
	) : (
		<Flex style={{ padding: '1.2rem' }} justify='between' fullWidth>
			<Flex gap={3} justify='start' align='center'>
				<Box width={19} height={20} />
				<Label style={{ color: 'black' }}>Expedite your order processing!</Label>
			</Flex>
			<Button size='small' linkStyle onClick={() => setIsPDMeasureContainer(true)} data-cart-pd-learn-how>Learn how</Button>
		</Flex>
	);
};

export default PhotoPd;
