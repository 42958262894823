import { Entry } from 'contentful';
import {
	SpacingValues,
	FieldSizingOptions,
	GridGapValues,
	GridLayoutOptions,
	MaxHeightOptions,
	MaxHeightValues,
	MaxWidthOptions,
	MaxWidthValues,
	ContentPositionOptions,
	CollectionListFields,
	NormalizedContentfulPageBlocks,
} from '@ts/contentful';
import { StringWithColon } from '@services/shopify/operations/get-multiple-collections';
import { normalizeContentfulEntry, normalizeCustomBlock } from '@utils/normalizers';
import { getShopifyIdFromBase64 } from './shopify';

/** Query Utility Functions */

export const generateQueryIdsFromContentful = (list: Entry<CollectionListFields>) => {
	const map = {} as Record<number, number>;

	const queryIds = list.fields.handles
		.map(handle => {
			const id = getShopifyIdFromBase64(handle);
			map[id] = id;
			return `(id:${id})`;
		})
		.join(' OR ') as StringWithColon;

	return [queryIds, map] as const;
};
/** Contentful Block/Component Normalization Mapping  */

export async function mapNormalizedContentfulBlocks({ entry: { fields } }): Promise<NormalizedContentfulPageBlocks> {
	const promises = fields.blocks.map(block => {
		if (block.sys.type === 'Link') return;

		const type = block.sys.contentType.sys.id;
		if (type === 'componentCustomBlock') return normalizeCustomBlock(block);
		return normalizeContentfulEntry(block);
	});
	const block = await Promise.all(promises);
	return { 
		blocks: block.filter(Boolean), 
		pageType: fields.pageType, 
		title: fields.title, 
		seoMetadata: {
			...fields.seoMetadata.fields,
			...(!!fields?.seoMetadata?.no_index ? { no_index: fields?.seoMetadata?.no_index } : {}),
			...(!!fields?.seoMetadata?.no_follow ? { no_follow: fields?.seoMetadata?.no_follow } : {}),
		},
	};
}

/** Field Value Manipulation Functions */
export function getGridGap(g: FieldSizingOptions): GridGapValues {
	const gaps: Record<FieldSizingOptions, GridGapValues> = {
		'None': '0',
		'X-Small': '0.4rem',
		'Small': '0.8rem',
		'Medium': '1.2rem',
		'Large': '1.6rem',
		'X-Large': '3.2rem',
	};

	return gaps[g];
}

export function getMaxWidth(mw: MaxWidthOptions): MaxWidthValues {
	const maxWidths: Record<Exclude<MaxWidthOptions, "Full-Width"> , MaxWidthValues> = {
		'Auto': 'auto',
		'375': '37.5em',
		'576': '57.6em',
		'767': '76.7em',
		'1024': '102.4em',
		'1280': '128em',
		'1440': '144em',
		// Dont set a "max-width" for full width values
	};

	return maxWidths[mw];
}

export function getMaxHeight(mh: MaxHeightOptions): MaxHeightValues {
	const maxHeights: Record<MaxHeightOptions, MaxHeightValues> = {
		'128': '12.8em',
		'256': '25.6em',
		'375': '37.5em',
		'444': '44.4em',
		'512': '51.2em',
		'767': '76.7em',
		'932': '93.2em',
	};

	return maxHeights[mh];
}

export function getSpacing(mb: FieldSizingOptions): SpacingValues {
	const margin: Record<FieldSizingOptions, SpacingValues> = {
		'None': '0',
		'X-Small': '0.8rem',
		'Small': '1.6rem',
		'Medium': '3.2rem',
		'Large': '4.8rem',
		'X-Large': '6.4rem',
	};

	return margin[mb];
}

export function getContentPosition(ca: ContentPositionOptions): Record<string, 'flex-start' | 'center' | 'flex-end'> {
	const position: Record<ContentPositionOptions, Record<string, 'flex-start' | 'center' | 'flex-end'>> = {
		'top-left': { alignItems: 'flex-start', justifyItems: 'flex-start' },
		'top-center': { alignItems: 'flex-start', justifyItems: 'center' },
		'top-right': { alignItems: 'flex-start', justifyItems: 'flex-end' },
		'center-left': { alignItems: 'center', justifyItems: 'flex-start' },
		'center': { alignItems: 'center', justifyItems: 'center' },
		'center-right': { alignItems: 'center', justifyItems: 'flex-end' },
		'bottom-left': { alignItems: 'flex-end', justifyItems: 'flex-start' },
		'bottom-center': { alignItems: 'flex-end', justifyItems: 'center' },
		'bottom-right': { alignItems: 'flex-end', justifyItems: 'flex-end' },
	};

	return position[ca];
}

export function getGridTemplate(num: GridLayoutOptions) {
	return `repeat(${num}, 1fr)`;
}

export function convertToRem(value: string | number) {
	return `${Number(value) / 10}rem`;
}
