import { useEffect, useState } from 'react';
import { useRelatedProducts } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { useRouter } from 'next/router';
import { NormalizedProduct, NormalizedCollection } from '@ts/product';
import { LOCALE_DICT, EMPTY_PRODUCT, EMPTY_VARIANT, PRODUCT_TYPES, LOCALE_CODES } from '@constants';
import { getShopifyIdFromGID } from '@utils/shopify';
import { getMultipleCollections } from '@services/shopify/operations/get-multiple-collections';
import { useRecommendProps } from '@ts/algolia';
import { getAllBaseFrames } from "@services/contentful";
import { ALGOLIA_SEARCH_INDEXES } from '@utils/algolia';

const { GIFT_CARD } = PRODUCT_TYPES;
const DEFAULT_RECOMMENDATION = 'classic-designs';

const recommendClient = recommend(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID, process.env.NEXT_PUBLIC_ALGOLIA_RECOMMEND_API_KEY);

type RecommendState = {
	recommendations: NormalizedProduct[];
	index: number;
	type?: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	isLoading: boolean;
	// fields may be added here for any component that needs to perform logic based on recommendation data
};

/** Use this Hook to fetch and filter Product Recommendation data.
 *
 * @reference [Algolia Recommend Hook](https://www.algolia.com/doc/ui-libraries/recommend/api-reference/recommend-react/useRelatedProducts/)
 */
const useRecommend = ({
	objectIds,
	types = null,
	collectionHandle = null,
	maxRecommendations = null,
	fallbackQuery = 'pink sun tops',
}: useRecommendProps ) => {
	const { locale } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	// Product to recommend by default
	const [fallbackCollection, setFallbackCollection] = useState<NormalizedCollection>(null);
	const indexName = ALGOLIA_SEARCH_INDEXES[countryCode]?.SHOPIFY_PRODUCTS;

	// State object that holds the Algolia response, and metafields for handling custom logic
	const [recommendState, setRecommendState] = useState<RecommendState>({
		recommendations: null,
		index: 0,
		type: null,
		isLoading: false,
	});

	// We expect each `objectId` to be a Shopify GQL Id
	const parsedGids = objectIds.reduce((a, v) => {
		a.push(getShopifyIdFromGID(v));
		return a;
	}, []);

	// Fetch Data from Algolia
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const { recommendations, status } = useRelatedProducts<any>({
		recommendClient,
		indexName,
		objectIDs: !!parsedGids[0] ? parsedGids : [],
		fallbackParameters: { query: fallbackQuery },
		...(maxRecommendations ? { maxRecommendations } : {}),
	});

	useEffect(() => {
		setRecommendState(prevState => ({
			...prevState,
			isLoading: status === 'loading',
		}));
	}, [status]);

	useEffect(() => {
		(async () => {
			let recommendationHandle = DEFAULT_RECOMMENDATION;

			if (types.includes(PRODUCT_TYPES.ACCESSORY)) {
				recommendationHandle = 'accessories';
			}

			if (types.includes(PRODUCT_TYPES.BASE_FRAME)) {
				const baseCollection = (await getAllBaseFrames(true)) as NormalizedCollection;

				return setFallbackCollection(baseCollection);
			}

			const defaultRec = await getMultipleCollections(collectionHandle || recommendationHandle, { country: LOCALE_DICT[locale].countryCode });
			setFallbackCollection(defaultRec);
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locale]);

	// Normalize data and Catch potential errors after fetch
	useEffect(() => {
		if (recommendations) {
			const normalized: NormalizedProduct[] = recommendations
				.filter(h => types.some(type => h.product_type.includes(type)))
				.reduce((result, hit) => {
					if (hit.inventory_available) {
						let price = hit.price;
						let compareAtPrice = hit.compare_at_price;
						if (locale === LOCALE_CODES.UK || locale === LOCALE_CODES.AU) {
							const currencyCode = LOCALE_DICT[locale].currencyCode.toLowerCase();
							const field = `market_pricing_${currencyCode}`;
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							price = (hit[`${field}`] as any)?.price || hit.price;
							compareAtPrice = (hit[`${field}`] as any)?.compare_at_price || hit.compare_at_price;
						}
						result.push({
							...EMPTY_PRODUCT,
							...{
								tags: hit.tags,
								type: hit.product_type,
								id: hit.id,
								handle: hit.handle,
								name: hit.title,
								variants: [
									{
										...EMPTY_VARIANT,
										...{
											id: `gid://shopify/ProductVariant/${hit.objectID}`, //! must be a Shopify GID !
											sku: hit.sku,
											handle: hit.handle,
											type: hit.product_type,
											name: hit.title,
											option:
												hit.product_type === GIFT_CARD ? `${hit.option1}/${hit.option2}` : hit.option1,
											availableForSale: true,
											requiresShipping: false,
											image: {
												url: hit.image,
											},
											price: {
												amount: price,
												currencyCode: LOCALE_DICT[locale].currencyCode,
											},
											compareAtPrice: compareAtPrice && {
												amount: compareAtPrice,
												currencyCode: LOCALE_DICT[locale].currencyCode,
											},
											product: {
												id: hit.id,
												handle: hit.handle,
												name: hit.title,
												type: hit.product_type,
												tags: [],
											},
										},
									},
								],
							},
						});
					}

					return result;
				}, [] as NormalizedProduct[]);
			setRecommendState(
				prev =>
					({
						...prev,
						recommendations: normalized.length ? normalized : fallbackCollection?.products,
					} as RecommendState)
			);
		}

		const timeout = setTimeout(() => {
			if (status !== 'idle') {
				const errorMessage = `There seems to be a problem while fetching algolia recommendations for product id: ${parsedGids.toString()} (this id was parsed from ${objectIds.toString()}). Last status captured is ${status}`;
				console.error(errorMessage);
			}
		}, 3000);

		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recommendations, fallbackCollection, status]);

	return [recommendState, setRecommendState] as const;
};

export default useRecommend;
