import { memo, forwardRef } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { EMPTY_PRODUCT, LOCALE_CODES, MEDIUM_LARGE_WIDTH, MEDIUM_WIDTH, TOP_FRAMES_PATH } from '@constants';
import Card from '@components/card/Card/Card';
import { Button } from '@components/common';
import { formatCurrency } from '@utils/shopify';
import useIsMobile from '@utils/hooks/useIsMobile';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { useBFContext } from '@context';
import { trackAddToCart, trackRemoveFromCart } from '@services/analytics/trackers';
import styles from './BuildFlowCard.module.scss';

type BuildFlowCardProps = {
	top: NormalizedVariant;
	collectionHandle: string;
	showTags?: boolean;
};

const returnTopProduct = (top: NormalizedVariant): NormalizedProduct => {
	return {
		...EMPTY_PRODUCT,
		id: top?.product?.id,
		type: top?.product?.type,
		handle: top?.product?.handle,
		name: top?.product?.name,
		tags: top?.product?.tags,
		variants: [
			{
				...top,
				metafields: {
					shipmentInfo: 'ships in 2-3 weeks',
				},
			},
		],
	};
};

const BuildFlowCard = forwardRef<HTMLDivElement, BuildFlowCardProps>(({ top, collectionHandle }: BuildFlowCardProps, ref) => {
	const { tops, handleTop, previewTop, setPreviewTop, isPreviewing, setIsPreviewing } = useBFContext();
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const isTablet = useIsMobile({ maxWidth: MEDIUM_LARGE_WIDTH });
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	if (!top.availableForSale) return null;

	const hasTop = tops[top.handle];
	const isPreviewingTop = isPreviewing ? previewTop?.id === top.id : false;

	const containerClasses = cn(styles.container, {
		[styles.containerSelected]: isPreviewingTop,
	});

	const buttonClasses = cn(styles.button, {
		[styles.buttonClicked]: hasTop,
	});

	const priceClasses = cn(styles.price, {
		[styles.priceClicked]: hasTop,
		[styles.priceCompare]: top.compareAtPrice?.amount && !hasTop,
	});

	const customButton = (
		<Button
			color={hasTop ? 'blue' : 'white'}
			extraClasses={buttonClasses}
			size='small'
			fullWidth
			removeEffects
			onClick={() => {
				handleTop({ ...top, collection: `${TOP_FRAMES_PATH}/${collectionHandle}` });
				setPreviewTop(top);
				setIsPreviewing(isMobile && hasTop ? false : true);
				hasTop
					? trackRemoveFromCart({ variant: top, path: `${TOP_FRAMES_PATH}/${collectionHandle}/` })
					: trackAddToCart({ variants: [top], path: `${TOP_FRAMES_PATH}/${collectionHandle}/` });
			}}
			{...(hasTop ? { 'data-delete-top': top.name } : { 'data-add-top': top.name, 'data-testid': 'add-top' })}
		>
			<span data-add-to-cart-text data-remove={hasTop || null} />
			<div className={styles.priceContainer}>
				<span data-pricing={top.price.amount} data-testid={'top-price'} className={priceClasses}>
					{formatCurrency(
						{
							amount: top.price.amount,
							currencyCode: top.price.currencyCode,
							locale,
						},
						showCurr
					)}
				</span>
				{top.compareAtPrice?.amount && !hasTop && (
					<span
						className={styles.comparePrice}
						data-pricing={top?.compareAtPrice?.amount}
					>
						{formatCurrency({ ...top.compareAtPrice, locale: locale }, showCurr)}
					</span>
				)}
			</div>
		</Button>
	);

	return (
		<Card
			key={`${top.handle}-top-card`}
			ref={ref}
			className={containerClasses}
			product={{
				...returnTopProduct(top),
				tags: [], // This is so we just show the Previewing tag
			}}
			variant={top}
			onMouseEnter={() => {
				if (isMobile) {
					return;
				}
				setPreviewTop(top);
				setIsPreviewing(true);
			}}
			aspectRatio={'2/1'}
			containerType={'build-flow'}
			contentType={'vertical'}
			buttonGroupType={'side-by-side'}
			showCarouselModal={isTablet}
			primaryAction={'custom'}
			primaryActionData={{ customComponent: customButton }}
			secondaryAction={'none'}
			isMobile={isMobile}
			showTags={false}
			showCollectionLozenge={true}
			hoverable
			compact
			ImgClickHandler={() => {
				if (previewTop?.handle !== top.handle) {
					setPreviewTop(top);
					setIsPreviewing(true);
				} else {
					setIsPreviewing(!isPreviewing);
				}
			}}
			dataTags={{
				button: {},
				zoom: {
					'data-tops-zoom': 'buildflow',
				},
				favorite: {},
			}}
		/>
	);
});

BuildFlowCard.displayName = 'BuildFlowCard';

export default memo(BuildFlowCard);
