/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { SUBMISSION_METHODS, READER_STRENGTHS, CUSTOMER_JOURNEYS, DELETE_BASE_FRAME } from '@constants';
import { LineItemCard } from '@components/card';
import { PrescriptionOptions, CheckboxUpsell } from '@components/cart';
import { Flex, Loading, Select, Img, Modal, Button } from '@components/common';
import { TypographyButton, Heading, Paragraph, Title } from '@components/typography';
import { useCart } from '@services/shopify';
import { Bundle, DoctorInfoAttributes, NormalizedCartLine } from '@ts/cart';
import { SubmissionMethod } from '@ts/poms';
import { NormalizedProduct } from '@ts/product';
import { CurrencyCode } from '@ts/shopify-storefront-api';
import PairCare from '@components/cart/PairCare';
import { useProductQuery } from '@services/shopify';
import { FRAME_COLORS, LENS_COLORS, LOCALE_DICT, generateSunglassImages, getBaseName, getCheckoutTokenFromGID, useParseBaseFrameVariant } from '@utils/index';
import variables from '@styles/export.module.scss';
import { CART_LINE_ATTRIBUTE_KEYS } from '@utils/constants/cart';
import { useCartContext } from '@context';
import useBaseFrameVariant, { createBaseFrameVariant, useBaseFrameVariantPrices } from '@services/shopify/hooks/useBaseFrameVariant';
import { BASE_FRAME_LENS_OPTIONS } from '@utils/constants/base-skus';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { fetchContentful } from '@services/contentful/client';
import { CopyGroupFields } from '@ts/index';
import { normalizeContentfulEntry } from '@utils/normalizers/normalize-contentful';
import { addcustomAttributes, getCollectionPathFromProperties, getEditPath, getReaderStrengthPayload, getSubmissionMethodPayload } from '../cart-utils';
import { emptyUpsellLine, emptyUpsellProduct } from '../cart-constants';
import styles from './BaseFrameBundle.module.scss';

const { REMIND } = SUBMISSION_METHODS;

type BaseFrameBundleProps = {
	cartId: string;
	bundle: Bundle;
	isLoading: boolean;
	LensUpsells: [NormalizedProduct, NormalizedProduct];
	pairCare: NormalizedProduct;
	forceMobileStyle?: boolean;
	subscriptionProduct?: NormalizedCartLine;
};

const BaseFrameBundle = ({ bundle, LensUpsells, pairCare, forceMobileStyle = false, isLoading, subscriptionProduct }: BaseFrameBundleProps) => {
	const { data: cart } = useCart();
	const { handleCartRemove, handleCartUpdate } = useCartContext();
	const router = useRouter();
	const { locale } = router;
	const countryCode = LOCALE_DICT[locale].countryCode;
	const withSubscription = !!subscriptionProduct;

	const { base, optimistic: optimisticState, key: bundleKey } = bundle;
	const { frame, prescription, insurance } = base;
	const { variant, properties, id: frameId } = frame;
	const { handle = '', option: fullVariantOption, name: productFrameTitle, type: varianttype, image: variantImg, name: frameTitle } = variant;
	const { url: variantImgUrl } = variantImg;
	const { _customerJourney, _readerStrength, _submissionMethod = REMIND, _customerType } = properties;
	const { id: insuranceId } = insurance ?? { id: '' };
	const { type: prescriptionType } = prescription;

	const isKidsFrame = _customerType === 'kids';
	const { frameColor, lensColor, lensType, rxType } = useParseBaseFrameVariant(fullVariantOption);
	const isBlueLensInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT);
	const isPremiumLensInCart = lensType.includes(BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS);
	const isSunglasses = _customerJourney === CUSTOMER_JOURNEYS.SUNGLASSES;
	const isNonRx = rxType === 'Non-RX'
	const isProgressives = rxType === 'Progressive'
	const isReaders = rxType === 'Readers';
	const isBlueLightUpsellVisible = !isBlueLensInCart && !isSunglasses;
	const isPremiumUpsellVisible = !isPremiumLensInCart && !isSunglasses && !isNonRx && !isReaders && !isKidsFrame;
	const isRxOptionsVisible = !!bundleKey && !isLoading && !isNonRx && !isReaders;

	const sunGlasses = [];
	const prescriptionLensPrice = { amount: 0, currencyCode: CurrencyCode.Usd }; 
	const submissionMethodState = useState(_submissionMethod as SubmissionMethod);
	const { data: product } = useProductQuery(handle, { country: countryCode });
	const variantImages = product?.variantImages;
	

	const BaseFrameVariant = useBaseFrameVariant({
		handle: base.frame.variant.handle,
		Color: frameColor,
		'Rx Type': rxType,
		Lens: createBaseFrameVariant(lensType),
		country: countryCode,
	})


	const { data: variantPrices, isLoading: variantPricesAreLoading } = useBaseFrameVariantPrices(base.frame.variant.handle, countryCode)

	const { data: lensUpsellCopyGroup } = useQuery(['lens-upsell-copy'], async () => {
		const copyGroup = await fetchContentful<CopyGroupFields>({
			'content_type': 'copyGroup',
			'fields.slug': 'build-flow-lens-options-copy',
			'limit': 1,
		})
		return normalizeContentfulEntry<CopyGroupFields>(copyGroup)
	})

	const sunglassImages = useMemo(() =>
		generateSunglassImages({
			name: getBaseName(productFrameTitle),
			baseColor: frameColor as FRAME_COLORS,
			lensColor: (isSunglasses ? lensColor : '') as LENS_COLORS,
			length: 3,
		}),
	[frameColor, sunGlasses[0]?.variant?.option, lensColor]);

	const removeAllFromBundle = () => {
		handleCartRemove([
			...(insuranceId && [insuranceId]),
			...(withSubscription ? [subscriptionProduct?.id]: []),
			frameId,
		], variant, getCollectionPathFromProperties(properties));
	};

	const updateReaderStrength = (strength: typeof READER_STRENGTHS[number]) => {
		const payload = getReaderStrengthPayload(strength, frame, properties);
		handleCartUpdate([payload]);
	};

	const updatePDMeasurement = async (pd: number) => {
		const payload = addcustomAttributes(
			{
				key: CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT,
				value: pd.toString(),
			},
			frame,
			properties
		);
		await handleCartUpdate([payload]);
	}

	const updateBundleSubmissionMethod = (extraProperties: DoctorInfoAttributes = {}) => {
		const isEditableBaseFrame = !properties || !frameId;
		const isSubmissionChanging = submissionMethodState[0] === _submissionMethod;

		if (isEditableBaseFrame || isSubmissionChanging) return;

		const payload = getSubmissionMethodPayload(properties, submissionMethodState[0], frame, extraProperties);
		frame && handleCartUpdate([payload]);
	};

	const memoizedRxOptions = useMemo(() => {
		return <PrescriptionOptions
			updatePDMeasurement={updatePDMeasurement}
			updateBundleSubmissionMethod={updateBundleSubmissionMethod}
			submissionMethodState={submissionMethodState}
			bundleKey={bundleKey}
			checkoutToken={getCheckoutTokenFromGID(cart?.id)}
			hasMeasuredPd={!!properties[CART_LINE_ATTRIBUTE_KEYS.PD_MEASUREMENT]}
		/>
	}, [base, submissionMethodState, isLoading]);

	const baseFrameImages = (image, index) => (
		<SwiperSlide key={index} className={'swiper-no-swiping'}>
			<Flex center>
				<Img needsPlaceholder alt={image.altText ?? ''} src={image.url} height={155} width={311} />
			</Flex>
		</SwiperSlide>
	);

	const handleEdit = async () => {
		const { basePath, queryParams } = getEditPath(bundleKey, frameColor, properties, _customerJourney, handle, withSubscription);
		const currentPath = router.asPath?.split('?')[0];

		if (currentPath === basePath) document.getElementById('minicart')?.click();

		router.push(basePath + queryParams);
	};

	if (!base) return null;
	if (isLoading || BaseFrameVariant.isLoading) return <Loading />;

	return (
		<Flex column gap={4} maxWidth className={forceMobileStyle ? styles.containerInDrawer : styles.container}>
			<Flex justify='end' gap={4}>
				<Button size='small' linkStyle onClick={handleEdit}>
					Edit
				</Button>
				<TypographyButton small style={{ color: variables.gray4 }}>
					|
				</TypographyButton>
				<Modal>
					<Modal.Trigger asChild>
						<Button size='small' linkStyle data-open-remove-modal={productFrameTitle}>Remove</Button>
					</Modal.Trigger>
					<Modal.Content center removePadding className={styles.deleteBaseFrameModal}>
						<Flex className={styles.imageContainer} align='center' justify='center'>
							<img src={isSunglasses ? sunglassImages[0].url : variantImgUrl} alt='frame variant' />
						</Flex>
						<div className={styles.bodyContainer}>
							<Heading tag='h5'>{DELETE_BASE_FRAME.TITLE}</Heading>
							<Paragraph>
								{DELETE_BASE_FRAME.LABEL} <b>{productFrameTitle}</b>.{' '}{withSubscription ? DELETE_BASE_FRAME.PROMPT_WITH_SUBSCRIPTION: DELETE_BASE_FRAME.PROMPT}
							</Paragraph>
							<Flex gap={3}>
								<Modal.Close asChild>
									<Button color='white'>Cancel</Button>
								</Modal.Close>
								<Modal.Close asChild>
									<Button
										onClick={() => removeAllFromBundle()}
										style={{ flex: 2 }}
										data-remove={productFrameTitle}
										data-type-remove={varianttype}
									>
										Remove
									</Button>
								</Modal.Close>
							</Flex>
						</div>
					</Modal.Content>
				</Modal>
			</Flex>
			{!!variantImages && !isLoading && (
				<Flex center>
					<Swiper
						loop
						direction='horizontal'
						speed={600}
						modules={[Autoplay, EffectFade]}
						effect='fade'
						autoplay={{ delay: 2500 }}
						noSwiping
						style={{ maxWidth: forceMobileStyle ? '33rem' : '' }}
						className={styles['swiper-container']}
					>
						{isSunglasses
							? sunglassImages.map((sunglassImage, index) => baseFrameImages(sunglassImage, index))
							: variantImages[frameColor].map((color, index) => baseFrameImages(color, index))
						}
					</Swiper>
				</Flex>
			)}
			<Paragraph className={styles.bundleTitle}>My Base Frame</Paragraph>
			{base && !isLoading && (
				<>
					<LineItemCard
						key={frameTitle}
						data={frame}
						dataSource={'shopify'}
						deletionCallback={removeAllFromBundle}
						optimistic={optimisticState}
						baseFrame={base}
						textOnly
					/>
					<PairCare bundle={bundle} pairCare={pairCare} />
				</>
			)}
			{_readerStrength && (
				<Flex align='center' justify='between' className={styles.readerStrengthDropdown}>
					<Title>Reader Strength</Title>
					<Select
						values={READER_STRENGTHS.map(rs => `+${rs}`)}
						handler={updateReaderStrength}
						placeholder={`+${_readerStrength}` ?? 'Choose Strength'}
					/>
				</Flex>
			)}
			<Paragraph className={styles.bundleTitle}>My Lenses</Paragraph>
			{/* RX TYPE */}
			<CheckboxUpsell
				disabled
				base={base}
				product={{
					...emptyUpsellProduct,
					name: rxType ?? prescriptionType,
					handle: rxType,
					price: prescriptionLensPrice,
				}}
				bundleKey={bundleKey}
				line={{
					...emptyUpsellLine,
					title: rxType,
				}}
				preSelectedTooltip
				rxType={rxType}
				variantPrices={variantPrices}
				currentVariant={BaseFrameVariant}
			/>
			{/* LENSES */}
			{lensType.map(lensLine => {
				return <CheckboxUpsell
					disabled={isSunglasses}
					key={`lensLine-${lensLine}`}
					base={base}					
					bundleKey={bundleKey}
					tooltip
					lensUpsell={lensLine}
					variantPrices={variantPrices}
					currentVariant={BaseFrameVariant}
				/>
				
			})}
			{/* LENS UPSELLS */}
			{isBlueLightUpsellVisible && <CheckboxUpsell base={base} lensUpsell={BASE_FRAME_LENS_OPTIONS.BLUE_LIGHT} lensUpsellCopy={lensUpsellCopyGroup} bundleKey={bundleKey} variantPrices={variantPrices} currentVariant={BaseFrameVariant}/>}
			{isPremiumUpsellVisible && <CheckboxUpsell base={base} lensUpsell={BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS} lensUpsellCopy={lensUpsellCopyGroup} bundleKey={bundleKey} tooltip variantPrices={variantPrices} currentVariant={BaseFrameVariant}/>}
			{isRxOptionsVisible && memoizedRxOptions}
		</Flex>
	);
};

export default BaseFrameBundle;
