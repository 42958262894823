import { createElement, ReactNode } from 'react';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { BLOCKS, MARKS, Block, Inline, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { nanoid } from 'nanoid';
import { CutPaper } from '@components/common';
import { Heading } from '@components/typography';
import { getSpacing, getMaxWidth } from '@utils/contentful';
import createCutPaperPath from '@utils/create-cut-paper';
import { findSubstringOnce } from '@utils/strings';
import { AlignmentOptions, ComponentText } from '@ts/contentful';
import styles from './ContentfulRichTextRenderer.module.scss';

const options = {
	renderMark: {
		[MARKS.BOLD]: (text: ReactNode) => <strong key={`${text}-key`}>{text}</strong>,
		[MARKS.ITALIC]: (text: ReactNode) => <i key={`${text}-key`}>{text}</i>,
		[MARKS.UNDERLINE]: (text: ReactNode) => (
			<span key={`${text}-key`} style={{ textDecoration: 'underline' }}>
				{text}
			</span>
		),
		[MARKS.CODE]: (text: ReactNode) => <code key={`${text}-key`}>{text}</code>,
	},
	renderNode: {
		[BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode) => <p>{children}</p>,
		[BLOCKS.HEADING_1]: (node: Block | Inline, children: ReactNode) => <Heading tag='h1' withBalancer>{children}</Heading>,
		[BLOCKS.HEADING_2]: (node: Block | Inline, children: ReactNode) => <Heading tag='h2'>{children}</Heading>,
		[BLOCKS.HEADING_3]: (node: Block | Inline, children: ReactNode) => <Heading tag='h3'>{children}</Heading>,
		[BLOCKS.HEADING_4]: (node: Block | Inline, children: ReactNode) => <Heading tag='h4'>{children}</Heading>,
		[BLOCKS.HEADING_5]: (node: Block | Inline, children: ReactNode) => <Heading tag='h5'>{children}</Heading>,
		[BLOCKS.HEADING_6]: (node: Block | Inline, children: ReactNode) => <Heading tag='h6'>{children}</Heading>,
		[BLOCKS.UL_LIST]: (node: Block | Inline, children: ReactNode) => <ul className={styles.ul}>{children}</ul>,
		[BLOCKS.OL_LIST]: (node: Block | Inline, children: ReactNode) => <ol className={styles.ol}>{children}</ol>,
		[BLOCKS.LIST_ITEM]: (node: Block | Inline, children: ReactNode) => <li className={styles.li}>{children}</li>,
		[INLINES.HYPERLINK]: (node: Block | Inline, children: ReactNode) => (
			<a
				className={styles.hyperlink}
				href={node.data.uri}
				target={node.data.uri.includes('loopreturns') ? '_blank' : '_self'}
			>
				{children}
			</a>
		),
	},
};

/**
 * Processes Contentful's RichText Editor input from 'Component: Text'
 *
 * @param param0
 * @returns JSX
 */
const ContentfulRichTextRenderer = (
	isMobile: boolean,
	{
		text,
		alignment: alignmentDesktop = 'left',
		alignmentMobile = 'left',
		blockMargin = 'None',
		inlinePadding = 'X-Small',
		normalTextOverride = 'Paragraph',
		maxWidth = '767',
		textColor = '#000',
		textColorMobile = undefined,
		hideTextMobile = false,
		normalTextFontSizeOverride = undefined,
		normalTextLineHeightOverride = undefined,
		paintText,
		paintTextColor,
		paintBackgroundColor,
		removeHeadingMargin,
		secondaryPaperColor,
		skinnyBanner = false,
	}: ComponentText
) => {
	const alignment = isMobile ? alignmentMobile ?? alignmentDesktop : alignmentDesktop;
	let nodes = documentToReactComponents(text, options);

	nodes = addPaintedElementToNodes({ nodes, paintText, paintTextColor, paintBackgroundColor, secondaryPaperColor, alignment });

	return (
		<div
			className={cn(styles['rich-text-wrapper'], styles[normalTextOverride], {
				[styles['remove-heading-margin']]: removeHeadingMargin,
				[styles['hide']]: hideTextMobile,
				[styles['font-size-override']]: normalTextFontSizeOverride,
				[styles['line-height-override']]: normalTextLineHeightOverride,
				[styles['skinny-banner']]: skinnyBanner,
			})}
			style={{
				color: isMobile && textColorMobile ? textColorMobile : textColor,
				textAlign: alignment,
				maxWidth: getMaxWidth(maxWidth),
				marginBlock: getSpacing(blockMargin),
				paddingInline: getSpacing(inlinePadding),
				...(normalTextFontSizeOverride && { fontSize: `${normalTextFontSizeOverride / 10}rem` }),
				...(normalTextLineHeightOverride && { lineHeight: `${normalTextLineHeightOverride / 10}rem` }),
			}}
			key={nanoid()}
		>
			{nodes}
		</div>
	);
};

/**
 * Processes the first instance of 'paintText' (if defined) and injects new React Nodes into the tree
 * while preserving string integrity if 'paintText' is found as a substring.
 *
 * @benefit This allows us to dynamically add painted backgrounds to ANY part of 'Component: Text'
 * without having to define this option as a Contentful Field.
 *
 * @returns ReactNodes[]
 */
function addPaintedElementToNodes({
	nodes,
	alignment,
	paintText,
	paintTextColor,
	paintBackgroundColor,
	secondaryPaperColor,
}: {
	nodes: ReactNode; //Note: Library does not have correct typing; should be ReactNode[], hence the array check below
	alignment?: AlignmentOptions;
	paintText?: string;
	paintTextColor?: string;
	paintBackgroundColor?: string;
	secondaryPaperColor?: string;
}): ReactNode {
	//Note: Only processes if paintText is present and NOT an empty string
	if (paintText === undefined || (typeof paintText === 'string' && paintText.trim() === '')) return nodes;

	if (Array.isArray(nodes)) {
		return nodes.reduce((accumulator, node, index) => {
			const children = node.props?.children ?? [];
			const trimPaintText = paintText.trim();

			if (children.length) {
				const match = children[0] && findSubstringOnce({ criteria: trimPaintText, str: children[0] });

				if (match) {
					const type = node.type;

					// Note: Adds preceeding and proceeding text elements if the painted text is a substring
					const nodeClass = paintBackgroundColor ? styles['inline-nodes'] : '';
					const splitStrings = children[0].replace(trimPaintText, '&').split('&');
					let prevStr = splitStrings[0];
					let nextStr = splitStrings[1];
					prevStr = node.props.tag ? (
						<Heading tag={node.props.tag} className={nodeClass}>
							{prevStr}
						</Heading>
					) : prevStr !== '' ? (
						createElement(type, { className: nodeClass }, prevStr)
					) : null;
					nextStr = node.props.tag ? (
						<Heading tag={node.props.tag} className={nodeClass}>
							{nextStr}
						</Heading>
					) : nextStr !== '' ? (
						createElement(type, { className: nodeClass }, nextStr)
					) : null;

					//--- Paint Text Element Creation ---//
					const path = createCutPaperPath('blueberry');
					const wrapperPath = createCutPaperPath('strawberry');
					const el = node.props.tag ? (
						<Heading tag={node.props.tag}>{trimPaintText}</Heading>
					) : (
						createElement(type, {}, trimPaintText)
					);

					const cutPaperBody = (
						<CutPaper
							cutPaperPath={path}
							textColor={paintTextColor ?? 'black'}
							backgroundColor={paintBackgroundColor ?? 'transparent'}
							padding={'4px 8px 6px'}
							className={cn(styles['cut-paper'], {
								[styles['cut-paper__left']]: prevStr && alignment === 'left',
								[styles['cut-paper__right']]: nextStr && alignment === 'right',
							})}
						>
							{el}
						</CutPaper>
					);

					const withCutPaper = !!secondaryPaperColor ? (
						<CutPaper
							cutPaperPath={wrapperPath}
							backgroundColor={secondaryPaperColor}
							padding={'4px 0px 6px 8px'}
						>
							{cutPaperBody}
						</CutPaper>
					) : cutPaperBody;

					const paintedElement = (
						<div className={cn(styles['painted'], styles[`painted-${alignment}`])} key={`${nodeClass}-${index}`}>
							{prevStr}
							{paintBackgroundColor ? withCutPaper : (
								<span style={{ color: paintTextColor, marginInline: '1ch' }}>{el}</span>
							)}

							{nextStr}
						</div>
					);

					accumulator.push(paintedElement);
					return accumulator;
				}
			}

			accumulator.push(node);
			return accumulator;
		}, []);
	}
}

export default ContentfulRichTextRenderer;
