import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useHasMounted } from '@utils/hooks';
import styles from './KlarnaPlacement.module.scss';

type KlarnaPlacementProps = {
	subtotal: number;
};

const KlarnaPlacement = ({ subtotal }: KlarnaPlacementProps) => {
	const { locale } = useRouter();
	const hasMounted = useHasMounted();

	useEffect(() => {
		if(hasMounted && window?.Klarna && locale !== 'en-CA') {
			try {
				window?.Klarna?.OnsiteMessaging?.refresh();
			} catch (error) {
				console.error(`Klarna script could not be refreshed! ERR: ${error}`)
			}
		}
	// `subtotal` is included here so that the Klarna script is kept in sync with props
	}, [hasMounted, locale, subtotal]);
	
	return locale !== 'en-CA' ? (
		<div id='klarna-placement' className={styles.container}>
			<klarna-placement
				data-key='top-strip-promotion-auto-size'
				data-locale={locale}
				data-purchase-amount={subtotal ?? 0}
			/>
		</div>
	) : null;
};

export default KlarnaPlacement;
