import { camelize } from '@utils/strings';

export const METAFIELD_TYPES_REQUIRING_PARSE = ['json', 'list.single_line_text_field', 'list.variant_reference'] as const;

export const productMetafieldIdentifiers = [
	{ namespace: 'custom_fields', key: 'searchable_tags' },
	{ namespace: 'custom_fields', key: 'design_subject' },
	{ namespace: 'custom_fields', key: 'design_type' },
	{ namespace: 'custom_fields', key: 'color' },
	{ namespace: 'custom_fields', key: 'shipment_info' },
	{ namespace: 'custom_fields', key: 'short_description' },
	{ namespace: 'custom_fields', key: 'group_key' },
	{ namespace: 'custom_fields', key: 'group_name' },
	{ namespace: 'custom_fields', key: 'licensing_image_caption' },
	{ namespace: 'custom_fields', key: 'licensing_image' },
	{ namespace: 'custom_fields', key: 'licensing_product_description' },
	{ namespace: 'custom_fields', key: 'licensing_logo' },
	{ namespace: 'custom_fields', key: 'product_video' },
	{ namespace: 'custom_fields', key: 'product_swatch' },
	{ namespace: 'custom_fields', key: 'lifestyle_image' },
	{ namespace: 'custom_fields', key: 'parent_bundles' },
	{ namespace: 'custom_fields', key: 'discount_tag' },
	{ namespace: 'custom', key: 'is_term_condition' },
	{ namespace: 'my_fields', key: 'frame_sizings' },
	{ namespace: 'my_fields', key: 'frame_colors' },
	{ namespace: 'my_fields', key: 'lens_colors' },
];

export const variantMetafieldIdentifiers = [
	{ namespace: 'custom_fields', key: 'shipment_info' },
	{ namespace: 'custom_fields', key: 'short_description' },
	{ namespace: 'custom_fields', key: 'min_threshold' },
	{ namespace: 'custom_fields', key: 'max_threshold' },
];

export const lozengeMetafieldIdentifiers = [
	{ namespace: 'custom_fields', key: 'lozenge_color' },
	{ namespace: 'custom_fields', key: 'lozenge_text_color' },
	{ namespace: 'custom_fields', key: 'lozenge_emoji' },
	{ namespace: 'custom_fields', key: 'lozenge_key' },
	{ namespace: 'custom_fields', key: 'lozenge_image' },
];

export const buildFlowCollectionMetafieldIdentifiers = [
	{ namespace: 'custom_fields', key: 'is_limited' },
	{ namespace: 'custom_fields', key: 'collection_logo_url' },
	{ namespace: 'custom_fields', key: 'small_collection_logo' },
	...lozengeMetafieldIdentifiers,
];

export const licensingCollectionMetafieldIdentifiers = [
	{ namespace: 'custom_fields', key: 'licensing_tagline' },
	{ namespace: 'custom_fields', key: 'licensing_description' },
	{ namespace: 'custom_fields', key: 'licensing_logo' },
	{ namespace: 'custom_fields', key: 'licensing_legal_copy' },
];

export const collectionMetafieldIdentifiers = [
	...lozengeMetafieldIdentifiers,
	...buildFlowCollectionMetafieldIdentifiers,
	...licensingCollectionMetafieldIdentifiers,
	{ namespace: 'disclaimer', key: 'title' },
	{ namespace: 'disclaimer', key: 'copy' },
	{ namespace: 'disclaimer', key: 'logo' },
	{ namespace: 'disclaimer', key: 'color' },
	{ namespace: 'disclaimer', key: 'text' },
	{ namespace: 'custom_fields', key: 'mobile_image' },
	{ namespace: 'custom_fields', key: 'countdown_launch_date' },
	{ namespace: 'custom_fields', key: 'countdown_background_color' },
	{ namespace: 'custom_fields', key: 'group_tops' },
	{ namespace: 'custom_fields', key: 'collection_banner_video' },
	{ namespace: 'custom_fields', key: 'base_frame_set' },
	{ namespace: 'custom_fields', key: 'gift_card_designs' },
	{ namespace: 'custom_fields', key: 'discount_tag' },
];

export const generateMetafieldQueries = (identifiers: { namespace: string; key: string }[]) => {
	return identifiers
		.reduce((result, current) => {
			const { namespace, key } = current;

			result.push(`
			... on HasMetafields {
				meta_${camelize(key)}: metafield(namespace: "${namespace}", key: "${key}") {
					id
					type
					value
					key
					reference {
						... on MediaImage {
							image {
								url
								height
								width
								altText
							}
						}
						... on Video {
							alt
							sources {
								url
								mimeType
								format
								height
								width
							}
							previewImage {
								url
								height
								width
								altText
							}
						}
					}
				}
			}
		`);

			return result;
		}, [])
		.join('');
};
