import { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { LOCALE_DICT, MEDIUM_WIDTH } from '@constants';
import { useHasMounted, useIsMobile, useLockedBody } from '@utils/hooks';
import { NavMenuProps } from '@ts/contentful';
import { Select } from '@components/common';
import { SelectValues } from '@components/common/Select';
import { useLocaleContext } from '@context';
import MenuGroup from '../MenuGroup';
import styles from './NavMenu.module.scss';

const NavMenu = ({ header, isMobileNavOpen, setMobileNavOpen }: NavMenuProps) => {
	const router = useRouter();
	const [activeItem, setActiveItem] = useState(null);
	const [hoverDebounceTimer, setHoverDebounceTimer] = useState(null);
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const isMounted = useHasMounted();
	const { handleLocaleChange } = useLocaleContext();

	const { locale } = useRouter();
	const showRefer = locale === 'en-US' || locale === 'en-CA';
	const countrySelectorIndex = isMobile ? 1 : (showRefer ? 3 : 2);
	const countryOptions = Object.values( LOCALE_DICT ).map(value => value.countryCode) as SelectValues;
	const countryCode = LOCALE_DICT[locale]?.countryCode;
	const showReferAFriend = locale === 'en-US' || locale === 'en-CA';

	const toggleDesktopSubNav = (shouldOpen, item) => {
		if (isMobile) {
			return;
		}
		setActiveItem(shouldOpen ? item.handle : null);
	};

	const toggleMobileSubNav = (shouldOpen, item) => {
		if (!isMobile) {
			return;
		}

		setActiveItem(shouldOpen ? item.handle : null);
	};

	const debounceHover = callback => {
		clearTimeout(hoverDebounceTimer);

		setHoverDebounceTimer(
			setTimeout(() => {
				callback();
			}, 200)
		);
	};

	const menuGroups = header
		? header?.menuGroups.map((menuGroup, index) => {
			const isReferAFriend = menuGroup.text === 'Refer a Friend';
			return (
				<Fragment key={`menuGroup-${index}`}>
					{(showReferAFriend && isReferAFriend || !isReferAFriend) && <MenuGroup
						key={`menu-group-${menuGroup.handle + index}`}
						header={header}
						menuGroup={menuGroup}
						debounceHover={debounceHover}
						toggleDesktopSubNav={toggleDesktopSubNav}
						toggleMobileSubNav={toggleMobileSubNav}
						activeItem={activeItem}
						index={index}
					/> }
					{index === header.menuGroups.length - countrySelectorIndex && isMounted && (
						<li className={styles['country-selector']}>
							<Select
								key={`country-selector-${countryCode}-${isMobile ? 'mobile' : 'desktop'}`}
								buttonProps={{
									size: isMobile ? 'medium' : 'small',
									fullWidth: isMobile,
									spaceBetween: true,
									color: 'white',
								}}
								handler={handleLocaleChange}
								values={countryOptions}
								placeholder={countryCode}
								prevSelection={countryCode}
								data-testid={'country-selector'}
								withIcon
								{...(isMobile && { label: 'Country' })}
							/>
						</li>
					)}
				</Fragment>
			);
		})
		: null;

	useEffect(() => {
		const handleRouteChange = () => {
			setActiveItem(null);
			setMobileNavOpen(false);
		};
		router.events.on('routeChangeComplete', handleRouteChange);
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router]);
	
	useLockedBody(isMobileNavOpen);

	return (
		<nav
			className={styles.nav}
			data-is-open={isMobileNavOpen}
			data-has-open-sub-nav={!!activeItem}
			onMouseLeave={() => {
				!isMobile && debounceHover(() => setActiveItem(null));
			}}
		>
			<ul id='navlist' className={styles['nav-list']} aria-labelledby='navbutton'>
				{menuGroups}
			</ul>
		</nav>
	);
};

export default NavMenu;
