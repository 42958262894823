import { Entry } from 'contentful';
import { normalizeContentfulEntry } from '@utils/normalizers';
import { DemoListFields } from '@ts/contentful';
import client from '../client';

export async function getTailoredExperience() {
	const { items } = await client.getEntries<DemoListFields>({
		'content_type': 'demoList',
		'include': 5,
	});

	const currentUrl = typeof window !== 'undefined' ? window.location.href : '';

	for (const demoListFields of items as Entry<DemoListFields>[]) {
		const landingPageTrigger = demoListFields.fields?.landingPageTrigger;
		const utmTerm = demoListFields.fields?.utmTerm;

		if (landingPageTrigger && utmTerm) {
			const urlIncludesTrigger = currentUrl.includes(landingPageTrigger);
			const urlIncludesUtmTerm = currentUrl.includes(`utm_term=${utmTerm}`);

			if (urlIncludesTrigger && urlIncludesUtmTerm) {
				return await normalizeContentfulEntry<DemoListFields>(demoListFields);
			}
		}
	}
	return null;
}
