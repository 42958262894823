import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
	BUILD_FLOW_STEP_DIRECTION,
	BUILD_FLOW_STEPS,
	CUSTOMER_JOURNEYS,
	LENS_COLORS,
} from '@constants';
import { COLLECTION_LISTS } from '@constants/contentful';
import { NormalizedVariant } from '@ts/product';
import { BFContextProps, BFProviderProps } from '@ts/index';
import { useHasMounted } from '@utils/hooks';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from '@utils/constants/base-skus';
import useBaseFrameVariant, { createBaseFrameVariant, useBaseFrameVariantPrices } from '@services/shopify/hooks/useBaseFrameVariant';
import { LOCALE_DICT, getOptionsForRxType, handelize } from '..';

const BFContext = createContext<BFContextProps>(null);

export function BFProvider({ children, props }: BFProviderProps) {
	const [collectionListSlug, setCollectionListSlug] = useState(COLLECTION_LISTS.BUILD_FLOW);
	const [defaultLens, setDefaultLens] = useState(props.defaultLens);
	const [demo] = useState(props.demo);
	const [frame] = useState(props.frame);
	const [frameColor, setFrameColor] = useState(props.frameColor);
	const [isEditMode, setIsEditMode] = useState(props.isEditMode);
	const [isPreviewing, setIsPreviewing] = useState(false);
	const [journey] = useState(props.journey);
	const [rxType, setRxType] = useState(props.rxType);
	const [sellingPlanId, setSellingPlanId] = useState(props.sellingPlanId);
	const [skipSubscription, setSkipSubscription] = useState(false);
	const [previewTop, setPreviewTop] = useState(props.previewTop);
	const [product, setProduct] = useState(props.product);
	const [readerRx, setReaderRx] = useState(props.readerRx ?? null);
	const lensOptions = getOptionsForRxType(props.lensOptions, rxType) 
	const [selectedLenses, setSelectedLenses] = useState(props.selectedLenses ?? [])
	const selectedLensesInVariantOptionForm = createBaseFrameVariant(selectedLenses);
	const lensColor = selectedLenses.find(lens => lens.includes('Sun'))?.split(" - ")?.[1] as LENS_COLORS 
	
	const [step, setStep] = useState(props.step);
	const [stepChange, setStepChange] = useState<BUILD_FLOW_STEP_DIRECTION>(null);
	const [tops, setTops] = useState(props.tops ?? {});
	const [variantImages, setVariantImages] = useState(props.variantImages);
	const { locale, query } = useRouter();
	const countryCode = LOCALE_DICT[locale].countryCode;
	const isSubscriptionActivated = useMemo(() => !!query?.subscriptionPath, [query]);

	useEffect(() => {
		setSellingPlanId(props.sellingPlanId);
	}, [props.sellingPlanId]);

	// TODO: fix this, speed up first load
	// const { data: variantPrices } = useBaseFrameVariantPrices(props.product.handle + `-${handelize(frameColor)}`, props.variantPrices)
	const { data: variantPrices } = useBaseFrameVariantPrices(props.product.handle + `-${handelize(frameColor)}`, countryCode)
	const BaseFrameVariant = useBaseFrameVariant({
		handle: props.product.handle + `-${handelize(frameColor)}`,
		Color: frameColor,
		'Rx Type': rxType,
		'Lens': selectedLensesInVariantOptionForm,
		country: countryCode,
	})

	const { data: selectedVariant } = BaseFrameVariant

	const subtotal = parseInt(selectedVariant?.variantBySelectedOptions?.price?.amount) + Object.values(tops).reduce((a,c) => a + c.price.amount, 0)
	const isMounted = useHasMounted();

	const isTailoredExperienceTest = (typeof window !== 'undefined' && window.isTailoredExperienceTest) ?? false;

	useEffect(() => {
		if ('collectionListSlug' in props && isTailoredExperienceTest && journey === CUSTOMER_JOURNEYS.EYEGLASSES) {
			setCollectionListSlug(props.collectionListSlug);
		}
	}, [isTailoredExperienceTest, journey, props]);

	function handleCustomLens(lensOption: BASE_FRAME_LENS_OPTIONS) {
		let updatedSelections = []
		if(selectedLenses.includes(lensOption)) {
			updatedSelections= selectedLenses.filter((option) => option !== lensOption)
			setSelectedLenses(updatedSelections)
		} else {
			updatedSelections = [...selectedLenses, lensOption]
			setSelectedLenses(updatedSelections)
		}
	}

	function handleTop(top: NormalizedVariant) {
		if (tops.hasOwnProperty(top.handle)) {
			const newTops = { ...tops };
			delete newTops[top.handle];
			setTops(newTops);
		} else {
			setTops({ ...tops, [top.handle]: top });
		}
	}

	// Step change handler
	useEffect(() => {
		const skipSubscription = !isSubscriptionActivated;

		if (stepChange === BUILD_FLOW_STEP_DIRECTION.NEXT) {
			setStepChange(null);
			const withInSteps = step + 1 < Object.keys(BUILD_FLOW_STEPS).length / 2;
			const skipCustomize = journey !== CUSTOMER_JOURNEYS.EYEGLASSES && rxType !== RX_TYPE.READERS;

			if (!withInSteps) return;
			if (step === BUILD_FLOW_STEPS.CUSTOMIZE && rxType === RX_TYPE.READERS && readerRx === null) return;

			if (step + 1 === BUILD_FLOW_STEPS.CUSTOMIZE && skipCustomize) {
				setStep(skipSubscription ? BUILD_FLOW_STEPS.TOP_FRAMES: BUILD_FLOW_STEPS.SUBSCRIPTION);
				return;
			}

			if (step + 1 === BUILD_FLOW_STEPS.SUBSCRIPTION && skipSubscription) {
				setStep(BUILD_FLOW_STEPS.TOP_FRAMES);
				return;
			}

			setStep(step + 1);
		}

		if (stepChange === BUILD_FLOW_STEP_DIRECTION.PREV) {
			setStepChange(null);
			const withInSteps = step - 1 >= 0;
			const skipCustomize = journey !== CUSTOMER_JOURNEYS.EYEGLASSES && rxType !== RX_TYPE.READERS;

			if (!withInSteps) return;

			if (step - 1 === BUILD_FLOW_STEPS.CUSTOMIZE && skipCustomize) {
				setStep(BUILD_FLOW_STEPS.LENS);
				return;
			}

			if(step - 1 === BUILD_FLOW_STEPS.SUBSCRIPTION && skipSubscription) {
				setStep(skipCustomize ? BUILD_FLOW_STEPS.LENS : BUILD_FLOW_STEPS.CUSTOMIZE);
				return;
			}

			setStep(step - 1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepChange]);

	/**
	 * Custom Lens Option Logic - Removals
	 * 
	 * 1. If the user selects Readers or Non-Prescription, remove Premium Plus
	 * 2. If the user selects Progressives RX first, then changes to another RX, then remove Progressive Lenses
	 */
	useEffect(() => {
		if (rxType === RX_TYPE.READERS || rxType === RX_TYPE.NON_RX) {
			setSelectedLenses(current => current.filter(lens => lens !== BASE_FRAME_LENS_OPTIONS.PREMIUM_PLUS))
		}
	}, [rxType]);

	useEffect(() => {
		if(props.product){
			setProduct(props.product);
		}
		if (step === BUILD_FLOW_STEPS.COLOR) {
			setFrameColor(props.frameColor);
			setTops(props.tops);
			setSelectedLenses(props.selectedLenses ?? []);
			setRxType(props.rxType);
			setReaderRx(props.readerRx);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])

	if (!isMounted) return;

	return (
		<BFContext.Provider
			value={{
				collectionListSlug,
				setCollectionListSlug,
				defaultLens,
				setDefaultLens,
				demo,
				frame,
				frameColor,
				setFrameColor,
				isEditMode,
				setIsEditMode,
				isPreviewing,
				setIsPreviewing,
				journey,
				lensColor,
				rxType,
				setRxType,
				previewTop,
				setPreviewTop,
				product,
				setProduct,
				readerRx,
				setReaderRx,
				lensOptions,
				selectedLenses,
				setSelectedLenses,
				step,
				setStep,
				setStepChange,
				subtotal,
				tops,
				handleCustomLens,
				handleTop,
				variantImages,
				setVariantImages,
				BaseFrameVariant,
				variantPrices: variantPrices || props.variantPrices,
				isSubscriptionActivated,
				setSellingPlanId,
				sellingPlanId,
				setSkipSubscription,
				skipSubscription,
				subscriptionProduct: props.subscriptionProduct,
			}}
		>
			{children}
		</BFContext.Provider>
	);
}

export function useBFContext() {
	return useContext(BFContext);
}
