import lensHeight from '@public/lens-height.png';
import bridgeWidth from '@public/bridge-width.png';
import lensWidth from '@public/lens-width.png';
import templeLength from '@public/temple-length.png';
import variables from '@styles/export.module.scss';
import { Divider, Flex, Img } from '@components/common';
import { Heading, Paragraph, Title } from '@components/typography';
import { Image } from '@ts/shopify-storefront-api';
import { FrameSizingData } from '@ts/product';
import styles from './FrameSizing.module.scss';

export type FrameSizingProps = {
	frameSizings: FrameSizingData;
	primaryImage: Image;
	name: string;
};

const FrameSizing = ({ frameSizings, primaryImage, name }: FrameSizingProps) => {
	const { units, lens, frame } = frameSizings;

	return (
		<>
			<Flex column align='center' justify='center' className={styles['main-image']}>
				<Img
					src={primaryImage.url}
					alt={`Sizing for ${name}`}
					width={primaryImage.width}
					height={primaryImage.height}
					style={{ maxWidth: '100%', height: 'auto' }}
				/>
				<svg width='640' height='12' viewBox='0 0 640 12' fill='none'>
					<line x1='3' y1='1' x2='3' y2='11' stroke={variables.blue1} strokeWidth='3' />
					<line
						x1='14.5'
						y1='6.5'
						x2='625.5'
						y2='6.5'
						stroke={variables.blue1}
						strokeWidth='3'
						strokeDasharray='12 12'
					/>
					<line x1='637' y1='11' x2='637' y2='1' stroke={variables.blue1} strokeWidth='3' />
				</svg>

				<Paragraph style={{ paddingTop: '1rem' }}>{`Frame Width: ${frame.width + units}`}</Paragraph>
			</Flex>
			<Flex column pad={4}>
				<Heading tag='h5' style={{ textAlign: 'left' }}>{`${name} Sizing`}</Heading>
				<Paragraph>Frame width is the full horizontal width of the front of the frame.</Paragraph>

				<div className={styles['glasses-row']}>
					<Img
						src={lensHeight.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{`Lens Height: ${lens.height + units}`}</Title>
						<Paragraph>The vertical diameter of one lens</Paragraph>
					</Flex>
				</div>
				<Divider alignment='horizontal' color={variables.gray2} style={{ margin: '0 auto' }} />
				<div className={styles['glasses-row']}>
					<Img
						src={bridgeWidth.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{`Bridge Width: ${frame.bridge + units}`}</Title>
						<Paragraph>The width of the bridge section</Paragraph>
					</Flex>
				</div>
				<Divider alignment='horizontal' color={variables.gray2} style={{ margin: '0 auto' }} />
				<div className={styles['glasses-row']}>
					<Img
						src={lensWidth.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{`Lens Width: ${lens.width + units}`}</Title>
						<Paragraph>The horizontal diameter of one lens</Paragraph>
					</Flex>
				</div>
				<Divider alignment='horizontal' color={variables.gray2} style={{ margin: '0 auto' }} />
				<div className={styles['glasses-row']}>
					<Img
						src={templeLength.src}
						noSrcset
						alt={`Sizing for ${name}`}
						width={250}
						height={125}
						style={{ width: '100%', height: 'auto' }}
					/>
					<Flex column align='start' justify='center'>
						<Title>{`Temple Length: ${frame.temple + units}`}</Title>
						<Paragraph>The length of the temple arm, from the front of the frame to the end tip</Paragraph>
					</Flex>
				</div>
			</Flex>
		</>
	);
};

export default FrameSizing;
