import { Drawer } from '@components/common';
import { Minicart, MinicartTrigger } from '@components/cart';

const MinicartDrawer = ({ closeSearchRef = null, openMinicartRef = null }) => {
	return (
		<Drawer>
			{openMinicartRef && (
				<Drawer.Trigger asChild>
					<div style={{ display: 'none' }} ref={openMinicartRef} />
				</Drawer.Trigger>
			)}
			<MinicartTrigger closeSearchRef={closeSearchRef} openMinicartRef={openMinicartRef} />
			<Drawer.Content data-minicart fullHeightOnMobile blurOverlay={false} grayBackground>
				<Minicart forceMobileStyle={true} isMinicart={true} />
			</Drawer.Content>
		</Drawer>
	);
};

export default MinicartDrawer;
