import Link from 'next/link';
import cn from 'classnames';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useRouter } from 'next/router';
import { Button } from '@components/common';
import { PerryLogo } from '@components/icons';
import { YouTubeIcon, InstagramIcon, FacebookIcon, TikTokIcon } from '@components/icons/SocialMedia';
import { Heading } from '@components/typography';
import { ContentfulRichTextRenderer } from '@components/utils';
import styles from './Footer.module.scss';

const Footer = ({ footer, page }) => {
	const { locale } = useRouter();
	const footerTestEnabled = useFeatureIsOn('footer-test'); // remove this after initial test on site
	const showReferAFriend = locale === 'en-US' || locale === 'en-CA';

	const menuGroups = footer?.menuGroups
		? footer.menuGroups.map(({ handle, text, subMenuGroup }) => (
			<li className={styles['footer__nav-item']} key={handle}>
				<Heading tag='h6'>{text}</Heading>
				<ul className={styles['footer__nav-submenu']}>
					{subMenuGroup.map(sub => {
						const isReferAFriend = sub.text === 'Refer a Friend' || sub.text === 'Rewards';
						return (showReferAFriend && isReferAFriend || !isReferAFriend) && (
							<li key={`help-section-sub-item-${sub.text}}`}>
								<Link href={sub.link}>{sub.text}</Link>
							</li>
						);
					})}
				</ul>
			</li>
		))
		: null;

	return (
		<div className={styles.container} data-page={page}>
			<footer role='contentinfo' className={styles['footer']} data-page={page} data-testid={'footer'}>
				<ul className={styles['footer__nav']}>{menuGroups}</ul>

				<div className={styles['footer__social']}>
					<Heading tag='h6' style={{ textAlign: 'left' }}>
						All the best things come in Pairs.
					</Heading>

					<ul className={styles['footer__social-list']}>
						<li>
							<a href='https://www.facebook.com/paireyewear/'>
								<FacebookIcon />
							</a>
						</li>
						<li>
							<a href='https://www.tiktok.com/@paireyewear/'>{<TikTokIcon />}</a>
						</li>
						<li>
							<a href='https://www.instagram.com/paireyewear/'>
								<InstagramIcon />
							</a>
						</li>
						<li>
							<a href='https://www.youtube.com/channel/UCsfEDry8WF9MRqYaCkQRipw'>
								<YouTubeIcon />
							</a>
						</li>
					</ul>
				</div>

				<ul className={footerTestEnabled ? styles['footer__service-test'] : styles['footer__service']}>
					<li className={styles['footer__service-item']}>
						<Link href='/terms-of-service'>Terms of Service</Link>
					</li>
					<li className={styles['footer__service-item']}>
						<Link href='/privacy-policy'>Privacy Policy</Link>
					</li>
					<li className={styles['footer__service-item']}>
						<Link href='/privacy-webform'>Privacy Web Form</Link>
					</li>
					<li className={cn(styles['footer__service-item'], styles['footer__copyright'])}>
						<span>&copy; 2024 Pair Eyewear</span>
					</li>
				</ul>

				<div className={styles['footer__logo']}>
					<PerryLogo />
				</div>

				<div className={styles['footer__bottom-section']}>
					{footer?.hasOwnProperty('contacts') && (
						<div className={styles['footer__contact']}>
							{footer.contacts.map((sub, index) => {
								return (
									<>
										<Button key={sub.text} href={sub.link} linkStyle size='small'>
											{sub.text}
										</Button>
										{index < footer.contacts.length - 1 && <div className={styles.line} />}
									</>
								);
							})}
						</div>
					)}

					{footer?.hasOwnProperty('disclaimer') && (
						<div className={styles['footer__disclaimer']}>
							{ContentfulRichTextRenderer(false, { text: footer.disclaimer, maxWidth: '1024' })}
						</div>
					)}
				</div>
			</footer>
		</div>
	);
};

Footer.displayName = 'Footer';

export default Footer;
