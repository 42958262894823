import { BaseFrameProductList } from "@ts/contentful";
import { getMultipleCollections } from "@services/shopify";
import { getShopifyIdFromBase64 } from "@utils/shopify";
import { NormalizedCollection, NormalizedProduct } from "@ts/product";
import { GetShopifyCollectionOptions, StringWithColon } from "@services/shopify/operations/get-multiple-collections";
import { transformCollectionsToProducts } from "@utils/normalizers";
import client from '../client';

export async function getAllBaseFrames(
	returnAsCollection = false,
	options: GetShopifyCollectionOptions = { skipImages: false },
	collectionIds?: string[],
): Promise<NormalizedProduct[] | NormalizedCollection> {
	const { items } = await client.getEntries<BaseFrameProductList>({
		'content_type': 'componentBaseFrameProductsList',
		'fields.slug': 'all-base-frames',
		'limit': 1,
		'include': 10,
	});

	const baseFrames = collectionIds ?? items[0].fields.baseFrames;
	const ids = baseFrames.map(collectionId => getShopifyIdFromBase64(collectionId));
	const response = await getMultipleCollections(ids.map(id => `(id:${id})`) as StringWithColon[], options);
	const products = transformCollectionsToProducts(response);

	if (returnAsCollection) {
		return {
			products,
			title: 'All Base Frames',
			handle: 'all-base-frames',
			image: null,
		};
	}

	return products;
}
