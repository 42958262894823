import { useCallback } from 'react';
import { useCartContext } from '@context';
import { CartNavButton } from '@components/cart';
import { Drawer } from '@components/common';

const MinicartTrigger = ({ closeSearchRef = null, openMinicartRef = null }) => {
	const { isMicrocartTest } = useCartContext();

	const handleMinicartClick = useCallback(() => {
		if (closeSearchRef?.current && openMinicartRef?.current) closeSearchRef.current.click();

		if (openMinicartRef?.current) openMinicartRef.current.click();
	}, [closeSearchRef, openMinicartRef]);

	const cartNavButtonProps =
		isMicrocartTest
			? {
				href: '/cart',
				['data-test']: 'microcart',
			}
			: {
				handleMinicartClick,
				title: 'Open Minicart',
				['data-test']: 'minicart',
			};

	return (
		<Drawer.Trigger asChild>
			<>
				<CartNavButton
					id='minicart'
					data-microcart-icon
					{...(cartNavButtonProps)}
				/>
			</>
		</Drawer.Trigger>
	);
};

export default MinicartTrigger;
