import { createClient } from 'contentful';

const client = createClient({ //TODO: revert before merge
	space: process.env.CONTENTFUL_SPACE_ID || process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
	accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_STAGING_ACCESS_TOKEN,
	host: 'cdn.contentful.com',
	environment: 'staging',
});

export type SearchParams = {
	content_type: ContentType;
	limit?: number;
	include?: number;
	[k: string]: unknown;
};

export type ContentType =
	| 'demoList'
	| 'collectionList'
	| 'homepageHeroCarousel'
	| 'homepageHeroCarouselSlide'
	| 'howItWorks'
	| 'componentFullWidthTextSection'
	| 'componentValueProposition'
	| 'componentButton'
	| 'componentButtonGrouping'
	| 'componentCountdownBanner'
	| 'componentFullWidthTextSection'
	| 'componentHero'
	| 'componentHeroCarousel'
	| 'componentLayout'
	| 'componentLoggedInMarketingGrid'
	| 'componentLoggedInAccountCard'
	| 'componentTwoPanelContentCard'
	| 'componentValueProposition'
	| 'componentProductList'
	| 'frequentlyAskedQuestions'
	| 'howItWorks'
	| 'marketingBlocks'
	| 'newsletter'
	| 'plpMarketingBlock'
	| 'blockPlp'
	| 'copyGroup';

export const fetchContentful = async <T>(query: SearchParams) => {
	const { items } = await client.getEntries<T>(query);
	return items[0];
};

export default client;
