import cn from 'classnames';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { PAIR_CARE_COPY, PREMIUM_ULTRA_THIN_COPY, EDIT_LENS_SELECTION, FAQ_URL, LOCALE_DICT, LOCALE_CODES } from '@constants';
import { Checkbox, Flex, Popover, Tooltip } from '@components/common';
import { TypographyButton, Paragraph, Title, Caption } from '@components/typography';
import { NormalizedProduct, NormalizedVariant } from '@ts/product';
import { Bundle, NormalizedCartLine } from '@ts/cart';
import { formatCurrency, parseBaseFrameVariant } from '@utils/shopify';
import variables from '@styles/export.module.scss';
import { useCartContext } from '@context';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE, BaseVariantPriceDictionary } from '@utils/constants/base-skus';
import { DynamicBaseFrameVariant, getVariantPriceDiff } from '@services/shopify/hooks/useBaseFrameVariant';
import { CopyGroupFields } from '@ts/index';
import styles from './CheckboxUpsell.module.scss';

const { blue2 } = variables;

const PairCareBody = ({ variant }: { variant: NormalizedVariant }) => {

	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;

	const message = (
		<Caption>
			{PAIR_CARE_COPY} See our{' '}
			<a
				className='blue-text'
				target='_blank'
				style={{
					color: blue2,
					textDecoration: 'underline',
				}}
				href={`${FAQ_URL}/articles/23239596609943-Warranty`}
			>
				FAQ
			</a>{' '}
			for more details.
		</Caption>
	);

	return (
		<Flex column fullWidth>
			<Flex style={{ width: '100%' }} justify='between'>
				<div>
					<Title>
						<span className={styles.pink}>Pair</span>
						<span className={styles.blue}>Care</span>
					</Title>
				</div>
				<Flex style={{ height: 'fit-content', flexShrink: '0' }} align='center' gap={3}>
					<Popover message={message} extraClasses={styles.pairCarePopover} shouldHover={true} />
					<TypographyButton small className={styles.price}>
						{formatCurrency({ amount: variant?.price?.amount, currencyCode: variant?.price?.currencyCode, locale }, showCurr)}
					</TypographyButton>
				</Flex>
			</Flex>
			<Paragraph style={{ marginTop: '0.6rem' }}>
				1-year warranty that offers full protection for scratched lenses or damaged frames.
			</Paragraph>
		</Flex>
	);
};

const EditabilityLensBody = ({ name, price, copy, tooltip = false, product, line, preSelectedTooltip = false }) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	return (
		<Flex column fullWidth>
			<Flex style={{ width: '100%' }} justify='between' align='center'>
				<Title>{name}</Title>
				<Flex style={{ height: 'fit-content', flexShrink: '0' }} align='center' gap={3}>
					{tooltip && name === 'Premium Plus' && <Tooltip message={PREMIUM_ULTRA_THIN_COPY} />}
					{preSelectedTooltip && <Tooltip message={EDIT_LENS_SELECTION} />}
					<TypographyButton small className={styles.price}>
						{formatCurrency(price, showCurr)}
					</TypographyButton>
				</Flex>
			</Flex>
			<Paragraph style={{ marginTop: '0.6rem' }}>{copy}</Paragraph>
		</Flex>
	);
};

const CheckboxUpsell = ({
	product,
	bundleKey,
	line = null,
	tooltip = false,
	base,
	disabled = false,
	preSelectedTooltip = false,
	variant = product?.variants[0],
	lensUpsell,
	rxType,
	variantPrices,
	currentVariant,
	lensUpsellCopy,
}: {
	product?: NormalizedProduct;
	bundleKey: string;
	line?: NormalizedCartLine;
	tooltip?: boolean;
	base: Bundle['base'];
	disabled?: boolean;
	preSelectedTooltip?: boolean;
	variant?: NormalizedVariant;
	lensUpsell?: BASE_FRAME_LENS_OPTIONS;
	rxType?: RX_TYPE;
	variantPrices?: BaseVariantPriceDictionary;
	currentVariant?: DynamicBaseFrameVariant;
	lensUpsellCopy?: CopyGroupFields
}) => {
	const { isCartMutating, handleCartAdd, handleCartRemove, handleBaseFrameUpsellAction } = useCartContext();
	const { locale } = useRouter();
	const currencyCode = LOCALE_DICT[locale].currencyCode;
	const safeToClick = !disabled && !isCartMutating;
	const info = parseBaseFrameVariant(base.frame.variant.option)
	const isChecked = !!line || info.lensType.includes(lensUpsell) || info.rxType === rxType;
	const variantOption = lensUpsell || rxType;

	const variantPriceDiff = variantOption && variantPrices && getVariantPriceDiff({
		lookup: variantPrices,
		current: currentVariant,
		lens: lensUpsell,
		rxType,
		locale,
		currencyCode,
	})

	const dynamicPrice = !variantPriceDiff ? false :
		currentVariant.data.variantBySelectedOptions.title.includes(lensUpsell)
			? variantPriceDiff.without
			: variantPriceDiff.with

	const classes = cn(styles.container, {
		[styles.checkedContainer]: isChecked,
		[styles.disabledCheckedContainer]: disabled,
	});

	const handleClick = useCallback(() => {
		if (!safeToClick) return;
		if (product?.handle === 'pair-care') {
			return isChecked
				? handleCartRemove([line.id])
				: handleCartAdd(
					[
						{
							id: product.id,
							variant: variant,
							quantity: 1,
							customAttributes: [
								{
									key: '_bundle_key',
									value: bundleKey,
								},
							],
						},
					],
					false
				);
		}
		return handleBaseFrameUpsellAction({
			lineToUpdate: base.frame,
			currentState: currentVariant.state,
			existingLenses: info.lensType,
			value: lensUpsell,
		})

	}, [currentVariant?.state, handleBaseFrameUpsellAction, lensUpsell, base?.frame, base?.frame, base?.frame, bundleKey, handleCartAdd, handleCartRemove, info, isChecked, line?.id, product, safeToClick, variant])

	return (
		<Checkbox
			checked={isChecked}
			className={classes}
			disabled={!safeToClick}
			isFetching={line?.optimistic}
			option='pair care'
			dataTags={
				isChecked
					? {
						[`data-cart-checkbox-remove`]: product?.name || lensUpsell,
					}
					: {
						[`data-cart-checkbox-add`]: product?.name || lensUpsell,
					}
			}
			handler={handleClick}
		>
			{variantOption ? (
				<EditabilityLensBody
					name={variantOption}
					price={dynamicPrice}
					copy={isChecked ? '' : lensUpsellCopy?.blocks?.find(block => block.slug.includes(lensUpsell.toLowerCase().replace(' ', '-')))?.description}
					tooltip={tooltip}
					product={variantOption}
					line={line}
					preSelectedTooltip={preSelectedTooltip}
				/>
			) : (
				<PairCareBody variant={variant} />
			)}
		</Checkbox>
	);
};
export default CheckboxUpsell;
