import { Entry } from 'contentful';
import { normalizeContentfulEntry } from '@utils/normalizers';
import { SiteSettings } from '@ts/contentful';
import client from '../client';

export async function getSiteSettings() {
	const { items } = await client.getEntries<SiteSettings>({
		content_type: 'siteSettings',
		limit: 1,
	});

	const [siteSettings] = items as Entry<SiteSettings>[];

	return await normalizeContentfulEntry<SiteSettings>(siteSettings);
}
