import cn from "classnames";
import { ReactNode } from "react";
import { NormalizedVariant } from "@ts/product";
import { Flex } from "@components/common";
import styles from './CardContent.module.scss';

type ContentProps = {
	containerType?: 'vertical' | 'horizontal' | 'line-item' | 'build-flow';
	type?: 'vertical' | 'horizontal' | 'micro' | 'vertical-micro';
	compact?: boolean;
	justify?: 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly' | 'normal';
	showTags?: boolean;
	children: ReactNode;
	hoverable?: boolean;
	noBorder?: boolean;
	variant?: NormalizedVariant;
};

const Content = ({ containerType, type = 'vertical', compact, noBorder, hoverable, children }: ContentProps) => {
	const classes = cn(styles.content, {
		[styles.contentVertical]: type === 'vertical',
		[styles.contentVerticalMicro]: type === 'vertical-micro',
		[styles.contentHorizontal]: type === 'horizontal',
		[styles.contentMicro]: type === 'micro',
		[styles.contentCompact]: compact,
		[styles.contentNoBorder]: noBorder,
		[styles.contentHoverable]: hoverable,
		[styles.contentBuildFlow]: containerType === 'build-flow',
		[styles.contentBorderLeft]: containerType === 'horizontal' && type === 'vertical',
	});

	// Utilizes CSS Grid instead of Flexbox for "micro" cards
	return type === 'micro' ? (
		<div className={classes}>
			{children}
		</div>
	) : (
		<Flex fullWidth column={type.includes('vertical')} align='start' className={classes}>
			{children}
		</Flex>
	);
};

export default Content;
