import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import { forwardRef } from 'react';
import variables from '@styles/export.module.scss';
import styles from '@components/common/Tooltip/Tooltip.module.scss';

type TooltipIconProps = {
	label: string;
};

const TooltipIcon = forwardRef<SVGSVGElement, TooltipIconProps>(({ label }: TooltipIconProps, ref) => {
	return (
		<AccessibleIcon.Root label={label}>
			<svg
				ref={ref}
				className={styles.icon}
				tabIndex={0}
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				xmlns='http://www.w3.org/2000/svg'
			>
				<circle cx='12' cy='12' r='11.25' fill='white' stroke={variables.gray2} strokeWidth='1.5' />
				<path
					d='M11.0341 14.0979V14.0072C11.0406 13.4153 11.1006 12.9443 11.2141 12.5943C11.3309 12.2442 11.4964 11.961 11.7105 11.7446C11.9246 11.5282 12.1825 11.3309 12.4842 11.1527C12.6788 11.0318 12.854 10.8966 13.0097 10.747C13.1655 10.5975 13.2887 10.4256 13.3796 10.2315C13.4704 10.0374 13.5158 9.82259 13.5158 9.58711C13.5158 9.3039 13.4477 9.05887 13.3114 8.85203C13.1752 8.64519 12.9935 8.48608 12.7664 8.3747C12.5426 8.26014 12.2928 8.20286 12.017 8.20286C11.7672 8.20286 11.5288 8.25378 11.3017 8.35561C11.0746 8.45744 10.8865 8.61655 10.7372 8.83294C10.588 9.04614 10.502 9.3214 10.4793 9.65871H9C9.02271 9.08592 9.17032 8.60223 9.44282 8.20764C9.71533 7.80986 10.0754 7.50915 10.5231 7.30549C10.974 7.10183 11.472 7 12.017 7C12.6139 7 13.1363 7.10979 13.5839 7.32936C14.0316 7.54574 14.3788 7.84964 14.6253 8.24105C14.8751 8.62928 15 9.08274 15 9.60143C15 9.95784 14.9432 10.2792 14.8297 10.5656C14.7161 10.8488 14.5539 11.1018 14.3431 11.3246C14.1354 11.5473 13.8856 11.7446 13.5937 11.9165C13.3179 12.0851 13.0941 12.2601 12.9221 12.4415C12.7534 12.6229 12.6302 12.8377 12.5523 13.0859C12.4745 13.3341 12.4323 13.6412 12.4258 14.0072V14.0979H11.0341ZM11.7689 17C11.5028 17 11.2741 16.9077 11.0827 16.7232C10.8913 16.5354 10.7956 16.3095 10.7956 16.0453C10.7956 15.7844 10.8913 15.5617 11.0827 15.3771C11.2741 15.1893 11.5028 15.0955 11.7689 15.0955C12.0316 15.0955 12.2587 15.1893 12.4501 15.3771C12.6448 15.5617 12.7421 15.7844 12.7421 16.0453C12.7421 16.2204 12.6967 16.3811 12.6058 16.5274C12.5182 16.6706 12.4015 16.7852 12.2555 16.8711C12.1095 16.957 11.9473 17 11.7689 17Z'
					fill={variables.gray3}
				/>
			</svg>
		</AccessibleIcon.Root>
	);
});

TooltipIcon.displayName = 'TooltipIcon';

export default TooltipIcon;
