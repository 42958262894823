import { Dispatch, useEffect, useState } from 'react';
import Script from 'next/script';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { AnimatePresence, m } from 'framer-motion';
import cn from 'classnames';
import { GLASSESON } from '@constants';
import { Button, Loading } from '@components/common';
import { Close } from '@components/icons';
import { Heading, Paragraph } from '@components/typography';
import { useLoadGlassesOn } from '@services/glasseson';
import { sendPupillaryDistance } from '@services/poms/operations/send-pupillary-distance';
import { fade, slideUp } from '@utils/motions';
import styles from './PdToolModal.module.scss';

type PdToolModalProps = {
	open: boolean;
	setOpen: Dispatch<boolean>;
	orderNumber?: string;
	setPdToolInit: (open: boolean) => void;
	setPdToolHasError: (open: boolean) => void;
	pdValueHandler?: (pd: number) => void;
	strategy?: 'onLoad' | 'onReady';
	externalTrigger?: boolean;
	hasScriptLoaded?: boolean;
	onScriptLoaded?: () => void;
	setPdNeeded?: (pdNeeded: boolean) => void;
	className?: string;
	classNameOverlay?: string;
	isException?: boolean;
};

const PdToolModal = ({
	open,
	setOpen,
	orderNumber,
	setPdToolInit,
	setPdToolHasError,
	pdValueHandler,
	strategy = 'onLoad',
	externalTrigger = true,
	hasScriptLoaded = false,
	onScriptLoaded,
	setPdNeeded,
	className,
	classNameOverlay,
	isException,
	...props
}: PdToolModalProps) => {
	const [isLoading, setLoading] = useState(false);
	const [pd, setPd] = useState(null);
	const [isPdMeasuringToolActive, setPdMeasuringToolActive] = useState(false);
	const [isPomsSubmitSuccessful, setPomsSubmitSuccessful] = useState(false);
	const [hasAppError, setAppError] = useState(false);
	const [pdScriptLoaded, setPdScriptLoaded] = useState(hasScriptLoaded);

	let message = null;

	const handlePdMeasuringToolResult = result => {
		window.glasseson.close();
		setPdMeasuringToolActive(false);
		// Block default 'send-to-poms' behavior and do something else with the pd value
		setPd(result?.data?.pd);
		if (pdValueHandler) {
			pdValueHandler(result?.data?.pd);
		}
	};

	const pdToolInitialized = useLoadGlassesOn({ setAppError, handlePdMeasuringToolResult, pdScriptLoaded });


	useEffect(() => {
		setPdToolInit(pdToolInitialized);
		setPdToolHasError(hasAppError);
		if (pdToolInitialized && open && !pd && !hasAppError) {
			window.glasseson
				.open('pd')
				.then(() => setPdMeasuringToolActive(true))
				.catch(e => {
					console.error('error opening pd tool', e);
					setAppError(true);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pdToolInitialized, hasAppError, open, pd]);

	useEffect(() => {
		const submitPd = async () => {
			if (isException) {
				setPomsSubmitSuccessful(true);
				setAppError(false);
				setPdNeeded(false);
			} else {
				try {
					setLoading(true);
					await sendPupillaryDistance(orderNumber, pd);
					setLoading(false);
					setPomsSubmitSuccessful(true);
					setAppError(false);
					setPdNeeded(false);
				} catch (e) {
					setLoading(false);
					setAppError(true);
				}
			}
		};
		if (orderNumber && pd) {
			submitPd();
		}
	}, [pd, orderNumber, setPdNeeded, isException]);

	const resetPdMeasuringTool = () => {
		window.glasseson.close();
		setPd(null);
		setPdMeasuringToolActive(false);
		setPomsSubmitSuccessful(false);
	};

	if (isPomsSubmitSuccessful) {
		message = (
			<>
				<Heading tag={'h2'}>PD Received!</Heading>
				<Paragraph>
					Your PD has been received successfully. Your PD is: <b>{pd}</b>. You may now close this page.
				</Paragraph>
			</>
		);
	}

	if (hasAppError) {
		message = (
			<>
				<Heading tag={'h2'}>Error!</Heading>
				<Paragraph>
					{pd ? (
						<>
							Your PD is: <b>{pd}</b>, but there was an issue recording your PD to our database.{' '}
						</>
					) : (
						<>Hmmm... There was an issue loading our measuring tool. </>
					)}
					Please refresh this page and try again, or contact us and send your information directly to {` `}
					<a href='mailto:hello@paireyewear.com'>hello@paireyewear.com</a>.
				</Paragraph>
			</>
		);
	}

	const handleOnLoad = () => {
		setPdScriptLoaded(true)
		onScriptLoaded && onScriptLoaded();
	};

	return (
		<>
			{strategy === 'onLoad' ? (
				<Script src={GLASSESON.SCRIPT_URL} onLoad={() => handleOnLoad()} />
			) : (
				<Script src={GLASSESON.SCRIPT_URL} onReady={() => handleOnLoad()} />
			)}
			<DialogPrimitive.Root open={open} onOpenChange={setOpen}>
				{!externalTrigger && (
					<DialogPrimitive.Trigger asChild>
						<Button label='Measure my PD Now' color='white' fullWidth />
					</DialogPrimitive.Trigger>
				)}
				<AnimatePresence>
					<DialogPrimitive.Portal>
						<DialogPrimitive.Overlay asChild className={cn(styles['overlay'], classNameOverlay)}>
							<m.div {...fade}></m.div>
						</DialogPrimitive.Overlay>
						<DialogPrimitive.Content
							{...props}
							asChild
							className={cn(styles.content, className)}
							onPointerDownOutside={() => resetPdMeasuringTool()}
						>
							<m.div {...slideUp}>
								<div id='glasseson' />
								{!isPdMeasuringToolActive && (
									<div className={styles.message}>
										{isLoading ? <Loading className={styles.loading} /> : message}
									</div>
								)}
								<DialogPrimitive.Close aria-label='Close' className={styles['close']} asChild>
									<Button linkStyle removeEffects onClick={() => resetPdMeasuringTool()}>
										<Close label='Close' />
									</Button>
								</DialogPrimitive.Close>
							</m.div>
						</DialogPrimitive.Content>
					</DialogPrimitive.Portal>
				</AnimatePresence>
			</DialogPrimitive.Root>
		</>
	);
};

PdToolModal.displayName = 'PdToolModal';
export default PdToolModal;
