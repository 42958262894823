import React, { forwardRef } from 'react';
import cn from 'classnames';
import { HeaderThin } from '@components/common';
import { ComponentProps } from '@ts/components';
import styles from './SidebarLayout.module.scss';

const Root = forwardRef<HTMLDivElement, ComponentProps<HTMLDivElement>>(
	({ forceMobileStyle = false, children, cartStyle = false, isMinicart = false, pdpOptimizationStyle = false, ...rest }, forwardedRef) => {
		const classes = cn(styles['root'], {
			[styles['root--responsive']]: !forceMobileStyle && !isMinicart,
			[styles['root--pdp-optimization']]: pdpOptimizationStyle,
			[styles['root--cart']]: cartStyle,
			[styles['root--minicart']]: isMinicart,
		});
		return (
			<div ref={forwardedRef} className={classes} {...rest}>
				{children}
			</div>
		);
	}
);

/** Holds the "main" content for the layout, e.g, Product Image on PDP, or "My Cart" section in Cart */
const Content = forwardRef<HTMLDivElement, ComponentProps<HTMLElement> & { cartStyle?: boolean }>(
	({ forceMobileStyle = false, cartStyle = false, isMinicart = false, children, ...rest }, forwardedRef) => {
		const classes = cn(styles['content'], {
			[styles['content--responsive']]: !forceMobileStyle && !isMinicart,
			[styles['content--cart']]: cartStyle,
			[styles['content--minicart']]: isMinicart,
		});

		return (
			<section ref={forwardedRef} className={classes} {...rest}>
				{children}
			</section>
		);
	}
);

/** Wraps children in a fixed section that takes up the rightmost third of the screen on desktop, and slots underneath the main content on mobile  */
const Sidebar = forwardRef<HTMLDivElement, ComponentProps<HTMLElement> & { cartStyle?: boolean }>(
	({ forceMobileStyle = false, pdpOptimizationStyle = false, className, children, cartStyle = false, isMinicart = false, ...rest }, forwardedRef) => {
		const classes = cn(styles['sidebar'], className, {
			[styles['sidebar--responsive']]: !forceMobileStyle && !isMinicart,
			[styles['sidebar--pdp-optimization']]: pdpOptimizationStyle,
			[styles['sidebar--cart']]: cartStyle,
			[styles['sidebar--minicart']]: isMinicart,
		});

		const contentClasses = cn(styles['sidebar-content'], {
			[styles['sidebar-content--responsive']]: !forceMobileStyle && !isMinicart,
			[styles['sidebar-content--pdp-optimization']]: pdpOptimizationStyle,
			[styles['sidebar-content--cart']]: cartStyle,
			[styles['sidebar-content--minicart']]: isMinicart,
		});
		return (
			<section ref={forwardedRef} className={classes} {...rest}>
				<div className={contentClasses}>{children}</div>
			</section>
		);
	}
);

/** Holds the "main" content for the layout, e.g, Product Image on PDP, or "My Cart" section in Cart */
const Header = forwardRef<HTMLDivElement, ComponentProps<HTMLElement> & { inDrawer: boolean; redirectTo?: string }>(
	({ inDrawer, redirectTo, ...rest }, forwardedRef) => {
		const classes = cn(styles['content'], {
			[styles['content--responsive']]: !inDrawer,
		});
		return <HeaderThin inDrawer={inDrawer} redirectTo={redirectTo} ref={forwardedRef} className={classes} {...rest} />;
	}
);

/** Places children underneath the main content on desktop, and underneath the sidebar content on mobile */
const Details = forwardRef<HTMLDivElement, ComponentProps<HTMLElement>>(
	({ forceMobileStyle = false, children, ...rest }, forwardedRef) => {
		const classes = cn(styles['details'], {
			[styles['details--responsive']]: !forceMobileStyle,
		});
		return (
			<section ref={forwardedRef} className={classes} {...rest}>
				{children}
			</section>
		);
	}
);

/** Places children underneath the details content on both mobile and desktop */
const Recommendations = forwardRef<HTMLDivElement, ComponentProps<HTMLElement>>(
	({ forceMobileStyle = false, children, ...rest }, forwardedRef) => {
		const classes = cn(styles['recommendations'], {
			[styles['recommendations--responsive']]: !forceMobileStyle,
		});
		return (
			<section ref={forwardedRef} className={classes} {...rest}>
				{children}
			</section>
		);
	}
);

/** Places children underneath the recommendations content on both mobile and desktop */
const Reviews = forwardRef<HTMLDivElement, ComponentProps<HTMLElement>>(
	({ forceMobileStyle = false, children, ...rest }, forwardedRef) => {
		const classes = cn(styles['reviews'], {
			[styles['reviews--responsive']]: !forceMobileStyle,
		});
		return (
			<section ref={forwardedRef} className={classes} {...rest}>
				{children}
			</section>
		);
	}
);

/** Wraps children in a container that is fixed to the bottom of the screen on mobile, and in the bottom right corner on desktop */
const CTA = forwardRef<HTMLDivElement, ComponentProps<HTMLDivElement>>(
	({ forceMobileStyle = false, children, ...rest }, forwardedRef) => {
		const classes = cn(styles['cta-container'], {
			[styles['cta-container--responsive']]: !forceMobileStyle,
		});
		return (
			<div ref={forwardedRef} className={classes} {...rest}>
				{children}
			</div>
		);
	}
);

Root.displayName = 'Root';
Content.displayName = 'Content';
Sidebar.displayName = 'Sidebar';
Header.displayName = 'Header';
Details.displayName = 'Details';
Recommendations.displayName = 'Recommendations';
Reviews.displayName = 'Reviews';
CTA.displayName = 'CTA';

const SidebarLayout = {
	Root,
	Content,
	Sidebar,
	Header,
	Details,
	Recommendations,
	Reviews,
	CTA,
};

export default SidebarLayout;
