import cn from 'classnames';
import { Footer, Header, Toast } from '@components/common';
import { Head } from '@components/utils';
import { useToastContext } from '@utils/context';
import styles from './Layout.module.scss';

const Layout = ({
	children,
	pageProps = {
		page: null,
		background: null,
		announcements: null,
		footer: null,
		header: null,
		headerVisible: null,
		hideFooter: null,
		thinHeader: null,
		redirectTo: null,
	},
}) => {
	const {
		announcements,
		footer,
		header,
		background,
		page,
		headerVisible = true,
		hideFooter = false,
		thinHeader = false,
		redirectTo,
	} = pageProps;
	const { toast } = useToastContext();

	const mainClasses = cn(styles['main'], {
		[styles['gray0']]: background === 'gray0',
		[styles['gray1']]: background === 'gray1',
		[styles['gray2']]: background === 'gray2',
		[styles['noHeader']]: !headerVisible,
	});

	//NOTE: Possibly add Layout Context
	return (
		<div className='layoutContainer'>
			<Head />
			<Header
				announcements={announcements}
				header={header}
				headerVisible={headerVisible}
				thin={thinHeader}
				redirectTo={redirectTo}
			/>
			<main className={mainClasses} data-page={page}>
				<div className={`pageContainer ${styles['content']}`}>{children}</div>
			</main>
			{!hideFooter && <Footer page={page} footer={footer} />}
			<Toast {...toast} />
		</div>
	);
};

export default Layout;
