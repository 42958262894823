/* eslint-disable max-lines */
import { useQueryClient } from '@tanstack/react-query';
import { NormalizedCollection, NormalizedProduct, NormalizedVariant } from '@ts/product';
import { BASE_FRAME_NAMES, PRODUCT_TYPES, GID_REG_EX, LENS_COLORS, FRAME_COLORS, FRAME_COLOR_HANDLES, LENS_COLOR_HANDLES } from '@constants';
import { setCartId } from '@services/shopify';
import { generateRandomNum } from './math';
import { normalizeProductType } from './normalizers';
import { BASE_FRAME_LENS_OPTIONS, RX_TYPE } from './constants/base-skus';
import { capitalize } from './strings';

const INVENTORY_THRESHOLD = 5;

export function getShopifyIdFromBase64(base64Id) {
	const decoded = atob(base64Id);
	const shopifyId = decoded.split('/').pop();
	return Number(shopifyId) || null;
}

/** Returns a descriptive caption to be displayed on a product card.
 *  The structure of the caption changes based on the product type and metafields
 * 	@example
 * 	if(variant.metafields.shortDescription) => "featuring Perry, our Mascot"
 * 	if(productType === BASE || ACCESSORY) => "in Crystal Clear"
 * 	if(productType === TOP) => "for The Kirby"
 * */
export const generateVariantCaption = (variant: NormalizedVariant, productType: NormalizedProduct['type']) => {
	if (variant.option === 'Default Title') return '';
	if (variant?.metafields?.shortDescription) return variant.metafields.shortDescription;
	if (!variant.option) return '';
	if (productType === PRODUCT_TYPES.ACCESSORY) return `in ${variant.option}`;
	if (productType.includes(PRODUCT_TYPES.BASE_FRAME)) return `in ${variant.option.split(' / ')[0]}`;
	if (productType === PRODUCT_TYPES.GIFT_CARD) return `${variant.option.split('/')[1]}`;
	return `for The ${variant.option}`;
};

export const isGID = (gid: string) => {
	if (!gid) return false;
	return GID_REG_EX.test(gid);
};

/** Extracts the object id of from a Shopify GraphQL ID */
export const getShopifyIdFromGID = (gid: string | number) => {
	if (!gid) return null;
	return gid.toString().match(GID_REG_EX) ? gid.toString().match(GID_REG_EX)[0] : gid.toString();
};

/** Returns the product variant matching the provided Variant Option
 *
 * @example
 * product.name === 'Black Sun Top'
 * getVariant(product, "Larkin") => Larkin Variant of Black Sun Top
 */
export const getVariantByOption = (p: NormalizedProduct, s: string, optionNumber?: number) => {
	//TODO: figure out how to clean, SKU restructuring blows this up
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	if (p.type === 'BASE_FRAME_SR' && !s.includes(' / ')) {
		return p.variants.find(v => v.option.split(' / ')[0] === s);
	}
	return p.variants.find(v => v.option === s);
};

/** Returns the product variant matching the provided Variant Property.
 *  Returns the first variant if no match is found.
 *
 * @example
 * product.variants.id === '120000'
 * getVariant(product, 'id', "120000") => Variant with id 120000
 */
export const getVariantByProperty = (
	p: NormalizedProduct,
	pr: keyof NormalizedProduct,
	s: NormalizedProduct[keyof NormalizedProduct]
) => {
	return !s
		? null
		: p.variants.find(v => {
			if (isGID(v[pr])) {
				return getShopifyIdFromGID(v[pr]) === s;
			}
			return v[pr] === s;
		}) ?? p.variants[0];
};

/** Formats currency based on amount and currencyCode from a Normalized Product or Variant
 */
export const formatCurrency = ({ amount, locale = 'en-US', currencyCode = 'USD', minDigits = 0, maxDigits = 0 }, showCurrencyCode = false) => {
	// If `currencyCode` is an empty string, use the default code
	if (currencyCode.length < 1) {
		currencyCode = 'USD'
	}
	const price = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: currencyCode,
		minimumFractionDigits: minDigits,
		maximumFractionDigits: maxDigits,
	}).format(Number(amount));
	return showCurrencyCode ? `${price} ${currencyCode}`: price;
};

export const normalizeHandle = (handle: string, reverse?: boolean) => {
	const options = {
		'base-frame-2': 'the-serra',
		'base-frame-3': 'the-twain',
		'large-base': 'the-larkin',
		'medium-rectangular-base': 'the-otero',
	};

	if (reverse) {
		return Object.keys(options).find(key => options[key] === handle) ?? handle
	}

	return options[handle] ?? handle;
};

/**
 * `generateVariantCaption` handles more product types and metafields, use that instead
 * @deprecated
 */
export const determineVariantCopy = (title: (typeof BASE_FRAME_NAMES)[number] | 'Default Title') => {
	if (!title || title === 'Default Title') return null;
	return BASE_FRAME_NAMES.includes(title) ? `for The ${title}` : `in ${title}`;
};

export const sortFavoritesByCollection = favoritesArray => {
	if (!favoritesArray) return [];
	const length = favoritesArray.length;
	const sorted = favoritesArray.sort((a, b) => a.product.product_type.localeCompare(b.product.product_type));

	// But move accessories to the end
	sorted.map(fav => fav.product.product_type === PRODUCT_TYPES.ACCESSORY && sorted.push(fav));
	sorted.splice(0, sorted.length - length);

	return sorted;
};

export const isVariantAvailableForSale = variant => {
	return variant.inventory_quantity > INVENTORY_THRESHOLD || variant.inventory_policy === 'continue';
};

export const findVisibleVariants = (collection: NormalizedCollection, frameShape: (typeof BASE_FRAME_NAMES)[number]) => {
	if (!collection.products || collection.products.length === 0) return [];

	const variants = collection.products.reduce((result, product) => {
		const normalizedType = normalizeProductType(product.type);
		switch (normalizedType) {
			case 'ACCESSORY':
				for (let i = 0; i < product.variants.length; i++) {
					const accessoryVariant = product.variants[i];
					if (accessoryVariant.availableForSale) {
						result.push({ ...accessoryVariant, name: product.name, metafields: accessoryVariant.metafields });
					}
				}
				break;
			case 'Gift Card':
				for (let i = 0; i < product.variants.length; i++) {
					const giftCardVariant = product.variants[i];
					if(collection.metafields.giftCardDesigns && !collection.metafields.giftCardDesigns.includes(giftCardVariant.id)){
						continue;
					}
					const giftCardDesign = giftCardVariant.option.split('/')[1];
					if(!result.some(v => v.option.split('/')?.[1] === giftCardDesign)){
						result.push({ ...giftCardVariant, name: product.name, metafields: giftCardVariant.metafields });
					}
				}
				break;
			case 'TOP_FRAME':
				const foundVariant = getVariantByOption(product, frameShape);
				if (foundVariant && foundVariant.availableForSale) {
					result.push({ ...foundVariant, name: product.name, metafields: foundVariant.metafields });
				}
				break;
			default:
				console.warn(`Unable to handle variants of type ${normalizedType}.`)
				break;
		}
		return result;
	}, [] as NormalizedVariant[]);

	return variants;
}

type VariantUrlGenerationProps = {
	variant: NormalizedVariant;
	option: NormalizedVariant['option'];
	collection?: string;
	sunLensColor?: LENS_COLORS;
	isBlueLight?: boolean;
	isSubscription?: boolean;
};

export const getParsedHandleandColor = (handle: string, color?: string, sunLensColor?: string, type: string = PRODUCT_TYPES.BASE_FRAME) => {
	let parsedHandle = handle;
	if (type === PRODUCT_TYPES.BASE_FRAME || type === PRODUCT_TYPES.BASE_FRAME_SR) {
		if (Object.values(FRAME_COLOR_HANDLES).some(colorHandle => handle.includes(colorHandle))) {
			const colorHandle = Object.values(FRAME_COLOR_HANDLES).find(colorHandle => handle.includes(colorHandle));
			parsedHandle = parsedHandle?.replace(`-${colorHandle}`, '');
			const parsedColor = capitalize(colorHandle.replace('-', ' ')).replace(' ', '%20');
			return { handle: parsedHandle, color: parsedColor };
		}
	}

	return { handle, color };
};

export const generateVariantUrl = ({
	variant,
	option,
	collection,
	sunLensColor,
	isBlueLight = false,
	isSubscription = false,
}: VariantUrlGenerationProps) => {
	const normalizedType = normalizeProductType(variant.type);
	const { handle, color } = getParsedHandleandColor(variant.handle, option, sunLensColor, normalizedType);
	switch (normalizedType) {
		case PRODUCT_TYPES.ACCESSORY:
			return `/accessories/${variant.handle}?variantId=${getShopifyIdFromGID(variant.id)}`;
		case PRODUCT_TYPES.BASE_FRAME || PRODUCT_TYPES.BASE_FRAME_SR:
			return `/${sunLensColor ? 'sunglasses' : isBlueLight ? 'blue-light' : 'eyeglasses'}/${collection ?? 'all'}/${handle}?frameColor=${color}${
				sunLensColor ? `&lensColor=${sunLensColor}` : ''}
				${isSubscription ? '&subscriptionPath=true' : ''}`;
		case PRODUCT_TYPES.GIFT_CARD:
			return `/accessories/${variant.handle}?variantId=${getShopifyIdFromGID(variant.id)}`;
		case PRODUCT_TYPES.TOP_FRAME:
			if (normalizedType.includes('BUNDLE')) {
				return `/top-frames/sets/${variant.handle}?frameShape=${option}`;
			} else {
				return `/top-frames/${collection ?? 'all-tops'}/${variant.handle}?frameShape=${option}`;
			}
		default:
			return '#';
	}
};

/** Note about `checkout_token`:
 * This is a field that exists on order webhook payloads, but not on storefront checkout objects
 *
 * Shopify Docs aren't clear about this, but it exists in the checkout id that we work with in this app
 *
 * So checkout.id will be something like "gid://shopify/Checkout/1234567890?key=abcdefg"
 *
 * And checkout_token will be the `1234567890` part of that string
 */
export const getCheckoutTokenFromGID = (gid: string) => {
	if (!gid || typeof gid !== 'string') return null;
	return gid.split('/')[4].split('?')[0];
};

export const getRandomVariant = (product: NormalizedProduct) => {
	const availableVariants = product.variants.filter(v => v.availableForSale);
	return availableVariants[generateRandomNum(0, availableVariants.length)];
};

export function reduceBaseFrame({ variantImages, options }: NormalizedProduct, color) {
	return {
		color: variantImages?.[color],
		colorOptions: options[0].values,
		images: variantImages,
	};
}

export type BaseFrameVariantInfo = {
	frameColor: FRAME_COLORS;
	rxType: RX_TYPE;
	lensType: Array<BASE_FRAME_LENS_OPTIONS>;
	lensColor?: LENS_COLORS;
	sku?: string;
}

export function parseBaseFrameVariant(option: string): BaseFrameVariantInfo {
	if (!option) return null;
	const [frameColor, rxType, fullLensType] = option.split(' / ') as [FRAME_COLORS, RX_TYPE, string];
	let lensColor: LENS_COLORS = null;
	let lensType = [];
	if(fullLensType.includes('Sun')) {
		const [sunLens, sunLensColor] = fullLensType.split(" - ") as [unknown, LENS_COLORS];
		lensColor = sunLensColor;
		lensType.push(fullLensType)
	} else if(fullLensType.includes('+')) {
		lensType = fullLensType.split(" + ");
	} else if(fullLensType !== 'None' && fullLensType !== 'No Add-ons') {
		lensType.push(fullLensType);
	}
	
	return {
		frameColor,
		rxType,
		lensType,
		lensColor,
	}
}

export function useParseBaseFrameVariant(option: string) {
	const queryClient = useQueryClient();

	try {
		return parseBaseFrameVariant(option);
	} catch (error) {
		console.error(`Error parsing base frame variant: ${option}. ${error}`)
		setCartId(null);
		queryClient.invalidateQueries(['cart', 'id']);
		
		// Return some defaults here so that no errors are thrown in the parent.
		// It doesn't matter if the defaults are incorrect because the cart gets reset.
		return {
			frameColor: FRAME_COLORS.BLACK,
			rxType: RX_TYPE.SINGLE_VISION,
			lensType: [],
			lensColor: null,
		}
	}
}
