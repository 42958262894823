import { useQuery } from '@tanstack/react-query';
import { BASE_FRAME_LENS_OPTIONS, BASE_FRAME_LENS_VARIANTS, RX_TYPE, BASE_FRAME_RX_TYPE_VARIANTS, BaseVariantPriceDictionary } from '@utils/constants/base-skus';
import { BASE_FRAME_COST, FRAME_COLORS } from '@constants';
import { alphabetize } from '@utils/arrays';
import { MoneyV2, SelectedOption } from '@ts/shopify-storefront-api';
import { parseBaseFrameVariant } from '@utils/shopify';
import fetchStorefrontApi from '../fetch-storefront-api';
import { cleanGraphqlResponse } from '..';
import baseFrameVariantQuery from '../queries/base-frame-variant-query';
import baseFrameVariantsQuery from '../queries/all-base-frame-variants-query';


export type VariantState = {
	handle: string;
	'Color': FRAME_COLORS;
	'Rx Type': BASE_FRAME_RX_TYPE_VARIANTS;
	'Lens': BASE_FRAME_LENS_VARIANTS;
    country?: string;
}

type VariantFetchResponse = {
	id: string;
	price: MoneyV2;
	selectedOptions: SelectedOption
    title: `${FRAME_COLORS} / ${RX_TYPE} / ${BASE_FRAME_LENS_VARIANTS}`
}

export type DynamicBaseFrameVariant = {
	state: VariantState;
	data: {
		variantBySelectedOptions: VariantFetchResponse
	};
	isLoading: boolean;
}

const defaultOptions: VariantState = {
	handle: 'the-casper-black',
	'Color': FRAME_COLORS.BLACK,
	'Rx Type': 'Single Vision',
	'Lens': 'None',
	country: 'US',
}


const reducer = (state: VariantState, action: Partial<VariantState>) => {
	return {
		...state,
		...action,
	}
}

export function createBaseFrameVariant(selectedLenses: Array<BASE_FRAME_LENS_OPTIONS>) {
	return selectedLenses.length > 0 ? alphabetize(selectedLenses).join(' + ') as BASE_FRAME_LENS_VARIANTS : 'None'
}

const useBaseFrameVariant = (state = defaultOptions): DynamicBaseFrameVariant => {

	const { data, isLoading } = useQuery(['variant-fetch', state, state.country], async () => {
		const thing = await fetchBaseFrameVariant({ 
			...state,
			"Rx Type": state['Rx Type'] || defaultOptions['Rx Type'],
		})
		return thing
	}, {
		keepPreviousData: true,
	})


	return { state, data, isLoading };
}

export const fetchBaseFrameVariant = async (options = defaultOptions) => {
	try {
		const { handle, country, ...argOptions } = options;
		const { product } = await fetchStorefrontApi(baseFrameVariantQuery, {
			variables: {
				handle: handle,
				selectedOptions: Object.entries(argOptions).map(([name, value]) => ({ name, value })),
				country: country ?? 'US',
			},
		})

		if (argOptions.Lens === 'None' && product.variantBySelectedOptions === null) {
			const variantOptions = {
				...argOptions,
				'Lens': 'No Add-ons',
			}
			const { product } = await fetchStorefrontApi(baseFrameVariantQuery, {
				variables: {
					handle: handle,
					selectedOptions: Object.entries(variantOptions).map(([name, value]) => ({ name, value })),
					country: country ?? 'US',
				},
			})
			const cleaned = cleanGraphqlResponse<{variantBySelectedOptions: VariantFetchResponse}>(product);
			return cleaned;	
		}

		const cleaned = cleanGraphqlResponse<{variantBySelectedOptions: VariantFetchResponse}>(product);
		return cleaned;
	} catch (error) {
		console.error(`Error fetching Base Frame Variant: ${error}`)	
	}
}

export const useBaseFrameVariantPrices = (handle: string, country = 'US', initialData?: BaseVariantPriceDictionary) => {
	return useQuery(['base-frame-variant-prices', handle, country], async () => {
		const variants = await fetchAllBaseFrameVariants(handle, country)
		return variants
	}, {
		initialData,
		//TODO: query doesn't seem to update when the frame color changes
	})
}

export const fetchAllBaseFrameVariants = async (handle: string, country: string) => {
	try {

		const { product } = await fetchStorefrontApi(baseFrameVariantsQuery, {
			variables: {
				handle,
				country,
			},
		})
		const { variants } = cleanGraphqlResponse<{ variants: any }>(product)
		
		return variants.reduce((a, c) => {
			if (c.title.includes('None')) {
				const altTitle = c.title.replace('None', 'No Add-ons')
				a[altTitle] = c.price
			}
			if (c.title.includes('No Add-ons')) {
				const altTitle = c.title.replace('No Add-ons', 'None')
				a[altTitle] = c.price
			}
			a[c.title] = c.price
			return a;
		}, {}) as BaseVariantPriceDictionary
	} catch (error) {
		console.error(`Error fetching all Base Frame Variants with handle ${handle}: ${error}`)
	}
}

export const getVariantPriceDiff = ({
	lookup,
	current,
	selectedLenses,
	lens,
	rxType,
	sunLensException,
	locale,
	currencyCode,
}: {
	lookup: BaseVariantPriceDictionary,
	current: DynamicBaseFrameVariant,
	selectedLenses?: Array<BASE_FRAME_LENS_OPTIONS>,
	lens?: BASE_FRAME_LENS_OPTIONS,
	rxType?: RX_TYPE
	sunLensException?: boolean
    locale: string
    currencyCode: string
}) => {

	const info = parseBaseFrameVariant(current.data.variantBySelectedOptions.title)
	// if(current.isLoading) return false;

	if(lens) {
		const currentVariantWithLens: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${current.state['Rx Type']} / ${createBaseFrameVariant([...new Set([...info.lensType, lens])])}`
		const currentVariantWithoutLens: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${current.state['Rx Type']} / ${createBaseFrameVariant(info.lensType.filter(v => v !== lens))}`
		
		try {
			return {
				with: {
					amount: parseInt(lookup[currentVariantWithLens].amount) - parseInt(current.data.variantBySelectedOptions.price.amount),
					currencyCode: lookup[currentVariantWithLens].currencyCode,
					locale,
				},
				without: {
					amount: parseInt(current.data.variantBySelectedOptions.price.amount) - parseInt(lookup[currentVariantWithoutLens].amount),
					currencyCode: lookup[currentVariantWithoutLens].currencyCode,	
					locale,
				},
			}
		} catch (error) {
			console.error(`Error getting pricing information for one or both of these variants: ${currentVariantWithLens}, ${currentVariantWithoutLens}
							${error}
				`)
			return {
				with: {
					amount: 0,
					currencyCode,
					locale,
				},
				without: {
					amount: 0,
					currencyCode,
					locale,
				},
			}
		}

		
	}

	if(rxType && info.lensColor && sunLensException) {
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType} / ${createBaseFrameVariant(info.lensType)}`;
		
		// TODO: what does "without" mean in this context? Single Vision is safe to assume for now...
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary =`${current.state.Color} / Non-RX / ${createBaseFrameVariant(info.lensType)}`;

		return {
			with: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - BASE_FRAME_COST[locale] - (info.lensColor.includes('Reflective') ? 15 : 0),
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: parseInt(lookup[currentVariantWithoutRx]?.amount) - BASE_FRAME_COST[locale] - (info.lensColor.includes('Reflective') ? 15 : 0),
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,	
				locale,
			},
		}
		
	}

	if(rxType) {
		
		const currentVariantWithRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / ${rxType} / None`;
		
		// TODO: what does "without" mean in this context? Single Vision is safe to assume for now...
		const currentVariantWithoutRx: keyof BaseVariantPriceDictionary = `${current.state.Color} / Single Vision / None`

		return {
			with: {
				amount: parseInt(lookup[currentVariantWithRx]?.amount) - BASE_FRAME_COST[locale],
				currencyCode: lookup[currentVariantWithRx]?.currencyCode,
				locale,
			},
			without: {
				amount: parseInt(lookup[currentVariantWithoutRx]?.amount) - BASE_FRAME_COST[locale],
				currencyCode: lookup[currentVariantWithoutRx]?.currencyCode,	
				locale,
			},
		}
		
	}
}

export default useBaseFrameVariant;
